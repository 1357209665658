angular.module('deitz').controller('addRateController', [

    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification',

    function(
        $scope, $rootScope, $state, commonFactory, Notification

    ) {

       
        $scope.formData={};
        $scope.formData.appear_rate = '0.00';
        $scope.formData.late_appear = '0.00';
        $scope.formData.Room = '0.00';
        $scope.formData.word_indexing_page_rate = '0.00';
        $scope.formData.rate = '0.00';
        $scope.formData.copy_rate = '0.00';
        $scope.formData.no_min_taken_charge = '0.00';
        $scope.formData.word_indexing_flat_rate = '0.00';
        $scope.formData.admin_fee = '0.00';
        $scope.formData.mini_script_per_page_rate = '0.00';
        $scope.formData.mini_script_flat_rate = '0.00';
        $scope.formData.postage = '0.00';
        $scope.formData.cd_charge = '0.00';
        $scope.formData.min_invoice_charge = '0.00';
        $scope.formData.web_charge_per_witness = '0.00';
        $scope.formData.video_conference_handling_and_connection_fee = '0.00';
        $scope.formData.reminder_charge = '0.00';
        $scope.formData.legalview_charge = '0.00';
        $scope.formData.deposition_insights_charge1 = '0.00';
        $scope.formData.deposition_insights_charge2 = '0.00';
        $scope.formData.deposition_insights_charge3 = '0.00';
        $scope.formData.digitization_charge = '0.00';
        $scope.formData.exhibit_charge = '0.00';
        $scope.formData.appearance_hourly= '0.00';
        $scope.formData.appearance_hourly_remote= '0.00';
        $scope.formData.appearance_hourly_nonsteno= '0.00';

        $scope.formData.appear_rate_remote= '0.00';
        $scope.formData.appear_rate_nonsteno= '0.00';
        $scope.formData.rate_remote= '0.00';
        $scope.formData.rate_nonsteno= '0.00';
        $scope.formData.copy_rate_remote= '0.00';
        $scope.formData.copy_rate_nonsteno= '0.00';
        $scope.formData.word_indexing_page_rate_remote= '0.00';
        $scope.formData.word_indexing_page_rate_nonsteno= '0.00';
        $scope.formData.non_appearance= '0.00';
        $scope.formData.cna= '0.00';
        $scope.formData.no_write= '0.00';
        $scope.formData.rough_ascii= '0.00';

        $scope.formData.realtime= '0.00';
        $scope.formData.exhibits_per_page= '0.00';
        $scope.formData.expedite_percentage_per_day= '0.00';
        $scope.formData.transcript_repository_storage= '0.00';
        $scope.formData.videographer_fee= '0.00';
        $scope.formData.video_archiving= '0.00';
        $scope.formData.web_amount = '0.00';
        $scope.formData.e_transcript = '0.00';
        $scope.formData.SOR = '0.00';
        $scope.formData.SORr = '0.00';
        $scope.formData.med_rate = '0.00';
        $scope.formData.exp_rate = '0.00';
        $scope.formData.daily_rate = '0.00';
        $scope.formData.immed_rate = '0.00';

        $scope.formData.lit_charges = '0.00';
        $scope.formData.show_calc = '';
        $scope.formData.charge1 = '0.00';
        $scope.formData.equipment_rental = '0.00';
        $scope.formData.video_sync = '0.00';
        $scope.formData.processing_fee = '0.00';
        $scope.formData.color_exhibit_charge = '0.00';
        $scope.formData.hyperlinked_exhibit_charge = '0.00';
        $scope.formData.appear_rate_arbitration = '0.00';
        $scope.formData.appear_rate_euo = '0.00';
        $scope.formData.rate_arbitration = '0.00';
        $scope.formData.rate_euo = '0.00';
        $scope.formData.appearance_hourly_arbitration = '0.00';
        $scope.formData.appearance_hourly_euo = '0.00';
        $scope.formData.copy_rate_arbitration = '0.00';
        $scope.formData.copy_rate_euo = '0.00';
        $scope.formData.word_indexing_page_rate_arbitration = '0.00';
        $scope.formData.word_indexing_page_rate_euo = '0.00';
        $scope.formData.no_transcript_depo_appear_rate = '0.00';
        $scope.formData.no_transcript_arbitration_appear_rate = '0.00';
        $scope.formData.no_transcript_euo_appear_rate = '0.00';
        $scope.save = function(type,next=false){ 
            if($scope.formData.name){
            $rootScope.showLoader(true);
            commonFactory.post('/api/rates/createRateDetails', $scope.formData)
            .success(function(response) {
                $rootScope.showLoader(false);
                if(response.status == 400){
                    Notification.error(response);
                }
                else{
                    Notification.success(response);
                    $state.go('rate_tables.rate_list');
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
                Notification.error(err);
            });
        }else{
            Notification.error('Please Enter Name');
        }
    }
 
   
    }]);
