angular.module('deitz').controller('addcaseController', [
    '$scope', '$rootScope', '$state', '$http', 'apiUrl', 'diaryFactory', 'commonFactory', '$timeout', 'Notification', '$q','$uibModal',

    function(
        $scope, $rootScope, $state,  $http, apiUrl, diaryFactory, commonFactory, $timeout, Notification, $q ,$uibModal
    ){

        $scope.diaryData = {};
        //default usa timezone
        $scope.diaryData.timezone = 6;
        $scope.generalData = {};
        $scope.title = "Add/Edit A Case To The Calendar";
        $scope.reporters = [];
        $scope.typist = [];
        $scope.our_suites = [];
        $scope.attorney = [];
        $scope.ebt = [];
        $scope.preventToInput = true;
        $scope.otherSideFirmSelected = true;
        $scope.heldAtLocationSelected  = false;
        $scope.isZoomLink = false;
        $scope.isZoomShow = true;
        $scope.openAttendeeType = false;
        $scope.isJobDateChange = false

        $scope.formSubmit = false;
        $scope.isSelectOrdFirm = false;
        $scope.validations = diaryFactory.validations;
        $scope.messages = diaryFactory.messages;
        $scope.LevelOfPayment_arr = []; //[{ id: 0, label: '0' }, { id: 1, label: '1' }, { id: 3, label: '3' }, { id: 4, label: '4' }, { id: 5, label: '5' }, { id: 6, label: '6' }, { id: 7, label: '7' }, { id: 8, label: '8' }, { id: 9, label: '9' }];
        $scope.diaryData.send_job_confirmation_on = [];
        $scope.orderingFirm = '';
        $scope.translator = [{ id: null, company_name: 'Please select Interpreter' }];
        $scope.salesperson_source_locked = false;
        $scope.isBrandInterCompany=false;
       

        /*Use for get client level */
         $http.get(apiUrl + '/api/get_client_level').then(function(response) {            
            $scope.LevelOfPayment_arr = response.data.data;            
         }); 
        
        
        $scope.time = ['09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM'];
        $scope.end_time = ['09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM','05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'];
        $scope.diaryData.DATE_TAKEN = moment().businessAdd(1)._d; //getting next business's day for date taken field.
        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};

        $scope.isConnectionMethodByFirm = false;
        //Show and hide with clockpicker toggle.
        $scope.IsVisibleTimeofExam = true;

        //get all sources for diary
        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.attorneySources=response.data.data;   
            console.log('Permission', $rootScope.permissions)   
        });
        
         //get all BussinesUnit for diary
         $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
        });
        commonFactory.get('/api/additionalinfo_list').then(function(response) { 
            $scope.additionalinfo_list = response.data.result.data;  
        });
        //get all job status
        $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
            $scope.JobStatusList = response.data.result.data;  
        });

        //get all biller
        $http.get(apiUrl + '/api/get-biller-list').success(function(response) {
            $scope.BillerList=response.result
        });


         //get all BussinesUnit for diary
         $http.get(apiUrl + '/api/get-user-roles-businessunit').then(function(response) { 
            $scope.rolesWithBusinessUnits = response.data.data;  
            // $scope.diaryData.business_unit_id = $scope.rolesWithBusinessUnits[0].business_unit_id
            $scope.diaryData.covering_business_unit_id = $scope.rolesWithBusinessUnits[0].business_unit_id
        });

        //Getting all languages.
        $scope.refreshLanguages = function () {
            $rootScope.showLoader(true);

            commonFactory.get('/api/all-active-languages')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.language) {
                        $scope.language = response.result.language || [];
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };
        $scope.refreshLanguages();
        
        // Get the auto Partner
        commonFactory.get('/api/config/get-auto-partner').success(function (response) {
            if (response.result) {
                $scope.auto_partners = response.result;
            }
        });
         //get Timezone list
         commonFactory.get('/api/timezoneListUsa').success(function (response) {
            if (response.result) {
                $scope.timezoneList = response.result ;
            }
        })
        .error(function (err) {
        });

        // Getting all rates card group
        $scope.refreshRateCardGroup = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/rateCardGroups').success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            });
        }
        $scope.refreshRateCardGroup();

        $scope.groupChange = function () {
            // Get rates based on rate group
            if ($scope.diaryData.rate_card_group_id) {
                commonFactory.post('/api/rate/getRateGroupActiveRate', {rate_card_group: [$scope.diaryData.rate_card_group_id]})
                    .success(function (response) {
                        if (response.result && response.result.rates) {
                            $scope.insuranceRates = response.result.rates;
                        }
                    })
                    .error(function (err) {
                    });
            } else {
                $scope.getInsuranceRateTable();
            }
        };

        $scope.currencyFormat = function(amt){
            if(amt == null){
                return '$0.00';
            }
            if(parseFloat(amt) >= 0){
                return '$'+parseFloat(amt).toFixed(2);
            }
            if(parseFloat(amt) < 0){
                return '-$'+ parseFloat(Math.abs(amt)).toFixed(2);
            }
        };

        //Getting all Translator.
        $scope.refreshTranslator = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/translators')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.translator) {
                        $scope.translator = $scope.translator.concat(response.result.translator || []);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };
        $scope.refreshTranslator();


        $scope.ShowHide_TimeofExam = function() {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisibleTimeofExam = $scope.IsVisibleTimeofExam ? false : true;
        };

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
        });


        commonFactory.get('/api/connection-methods')
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.connectionMethods = response.result.resources;
            }
        });

        $scope.onSelectChangeSalesperson = function(){
            $scope.diaryData.reporting_sales_ids = [];
            $scope.insuranceRates.filter(function(item){                
				if($scope.diaryData.insurance_rate_id == item.InsuranceRateID && item.salesperson_id != 0) {					
					$scope.diaryData.reporting_sales_ids.push(item.salesperson_id);
				}
            });
        }
        $scope.onSelectSalesperson = function() {
            
            //First removing all reporting salesperson email address.
            $scope.salesPersonsList.filter(function(item){
                 $scope.diaryData.send_job_confirmation_on = $scope.diaryData.send_job_confirmation_on.filter(function(conf){
                    return conf.text != item.Emai_Address;
                });
            });

            //Inserting reporting salesperson email address.
            $scope.salesPersonsList.filter(function(item){
                var IDs = $scope.diaryData.reporting_sales_ids;
                if(IDs.includes(item.id) && item.auth_user && item.Emai_Address){
                    var is_email_exists = false; 
                    $scope.diaryData.send_job_confirmation_on.filter(function(conf){
                        if(conf.text == item.Emai_Address){
                            is_email_exists = true;
                        }
                    });
                    if(!is_email_exists){
                        $scope.diaryData.send_job_confirmation_on.push({text: item.Emai_Address});
                    }
                }
            });
        };

        $scope.claimrepModal = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/code_tables/claim_rep_name/browse/claim_rep_modal.html',
                controller: 'addClaimrepnameController',
                resolve: {

                }
            });
        };

        $scope.editclaimrepModal = function(data, index) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/code_tables/claim_rep_name/browse/claim_rep_modal.html',
                controller: 'editClaimrepnameController',
                resolve: {
                    claim_rep_data: function claimrepData() {
                        return data;
                    }
                }
            });
        };

        //Next & Previous button of field set.
        if (!$state.params.id) {
            $scope.goto_generalinfo = function() {
                $state.go('diary.addcase.step_two');
            }
        }

        //On page loading method called.
        $scope.AttorneyFirminit = function() {
            //Getting Inoursuites data
            commonFactory.get('/api/oursuites')
                .success(function(response) {
                    if (response.result && response.result.our_suites) {
                        $scope.our_suites = response.result.our_suites || '';
                    }
                })
                .error(function(err) {});


            //Getting all locations from locations table.
            commonFactory.get('/api/getLocation')
                .success(function(response) {
                    if (response.result && response.result.data) {
                        $scope.locations = response.result.data || '';
                    }
                })
                .error(function(err) {});
        };

        //Getting Attorney Firm office_code
        $scope.foundFromElaw = false;

        $scope.attorneyofficedata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Refreshing locations
        $scope.refreshLocations = function() {
            commonFactory.get('/api/getLocation')
                .success(function(response) {
                    if (response.result && response.result.data) {
                        $scope.locations = response.result.data || '';
                    }
                })
                .error(function(err) {});
        };

        //Refreshing our suites.
        $scope.refreshOursuites = function() {
            commonFactory.get('/api/oursuites')
                .success(function(response) {
                    if (response.result && response.result.our_suites) {
                        $scope.our_suites = response.result.our_suites || '';
                    }
                })
                .error(function(err) {});
        };

        //Refreshing Firm and get related firm information
        $scope.refreshFirm = function(id) {
            if (id) {
                $rootScope.showLoader(true);
                var data = { attorney_id: id }
                commonFactory.post('/api/getAttorneyfirmdata', data)
                    .success(function(response) {
                        $scope.diaryData.attorney_id = response.result.attorney_data || '';

                        //assigning status and beeper(autopartner) of firm.
                        $scope.firm_status = response.result.attorney_data.STATUS;
                        angular.forEach($scope.auto_partners,function(value, key){
                            if(value.code == response.result.attorney_data.BEEPER){                
                                $scope.firm_beeper = value.text;
                            }
                        });
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });

                var firmdata = { firm_id: id }
                $timeout(function() {
                    commonFactory.post('/api/attorneybyfirm', firmdata)
                        .success(function(response) {
                            if (response) {
                                if (response && response.result && response.result.attorney_data) {
                                    $scope.attorney = response.result.attorney_data || '';
                                }
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });
                }, 500);

                var ebtdata = { firm_id: id }
                $timeout(function() {
                    commonFactory.post('/api/ebtbyfirm', ebtdata)
                        .success(function(response) {
                            if (response) {
                                if (response && response.result && response.result.ebt_data) {
                                    $scope.ebt = response.result.ebt_data || '';
                                }
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });
                }, 500);
                $rootScope.showLoader(false);
            } else {
                $rootScope.showLoader(false);
            }
        };

        $scope.refreshDiaryData = function (id, key) {
            if (id) {
                $rootScope.showLoader(true);
                var data = {attorney_id: id}
                commonFactory.post('/api/getAttorneyfirmdata', data)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        $scope.diaryData[key] = response.result.attorney_data || '';
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
                $rootScope.showLoader(false);
            } else {
                $rootScope.showLoader(false);
            }
        };

        //Select Action Dropdown for Auto populate Location Fields.
        $scope.$watch('diaryData.location_action', function(newValue, oldValue) {

            $scope.preventToInput = true;

            if (newValue && newValue != undefined) {

                if (newValue !== 'our_suites'){
                    $scope.diaryData.ToBeHeldAt = "";
                }

                if (newValue === 'attorneyfirm' && ($scope.diaryData.attorney_id == undefined || $scope.diaryData.attorney_id == "")) {
                    $scope.diaryData.ToBeHeldAt = "";
                    Notification.error("Please select scheduling firm");

                    $scope.diaryData.location_action = '';
                    clearActionfield();
                }
                if (newValue === 'heldatfirm' && ($scope.diaryData.otherfirmlocation == undefined || $scope.diaryData.otherfirmlocation == "")) {
                    $scope.diaryData.ToBeHeldAt = "";
                    Notification.error("Please select otherside firm");

                    $scope.diaryData.location_action = '';
                    clearActionfield();
                }
                if (newValue === 'our_suites' && ($scope.diaryData.ToBeHeldAt == undefined || $scope.diaryData.ToBeHeldAt == "")) {
                    Notification.error("Please select our suites");

                    $scope.diaryData.location_action = '';
                    clearActionfield();
                }

                if (newValue === 'attorneyfirm') {
                    $rootScope.showLoader(true);
                    if ($scope.diaryData && $scope.diaryData.attorney_id) {
                        $timeout(function() {
                            var data = { attorney_id: $scope.diaryData.attorney_id }
                            commonFactory.post('/api/getAttorneyfirmdata', data)
                                .success(function(response) {
                                    $scope.diaryData.attorney_id = response.result.attorney_data || '';

                                    $scope.diaryData.HELD_AT1 = response.result.attorney_data.NAME || '';
                                    $scope.diaryData.HELD_AT2 = response.result.attorney_data.ADDR_LINE1 || '';
                                    $scope.diaryData.HELD_AT3 = response.result.attorney_data.ADDR_LINE2 || '';
                                    $scope.diaryData.HELD_AT_CITY = response.result.attorney_data.CITY || '';
                                    $scope.diaryData.HELD_AT_STATE = response.result.attorney_data.STATE || '';
                                    $scope.diaryData.HELD_AT_ZIPCODE = response.result.attorney_data.ZIP || '';
                                    $scope.diaryData.HA_PHONE = response.result.attorney_data.PHONE || '';
                                    $scope.diaryData.CON_PHONE = response.result.attorney_data.PHONE || '';
                                    $scope.diaryData.LOCATION = response.result.attorney_data.LOCATION || '';
                                    $scope.diaryData.CONTACT = response.result.attorney_data.CONTACT || '';
                                    $scope.diaryData.claim_number_mask = response.result.attorney_data.claim_number_mask || '';
                                })
                                .error(function(err) {
                                    $rootScope.showLoader(false);
                                });
                        }, 100);
                    }
                    $rootScope.showLoader(false);
                }
                else if (newValue === 'heldatfirm') {
                    $rootScope.showLoader(true);
                    if ($scope.diaryData && $scope.diaryData.otherfirmlocation) {
                        $timeout(function() {
                            var data = { attorney_id: $scope.diaryData.otherfirmlocation }
                            commonFactory.post('/api/getAttorneyfirmdata', data)
                                .success(function(response) {
                                    $scope.diaryData.otherfirmlocation = response.result.attorney_data || '';

                                    $scope.diaryData.HELD_AT1 = response.result.attorney_data.NAME || '';
                                    $scope.diaryData.HELD_AT2 = response.result.attorney_data.ADDR_LINE1 || '';
                                    $scope.diaryData.HELD_AT3 = response.result.attorney_data.ADDR_LINE2 || '';
                                    $scope.diaryData.HELD_AT_CITY = response.result.attorney_data.CITY || '';
                                    $scope.diaryData.HELD_AT_STATE = response.result.attorney_data.STATE || '';
                                    $scope.diaryData.HELD_AT_ZIPCODE = response.result.attorney_data.ZIP || '';
                                    $scope.diaryData.HA_PHONE = response.result.attorney_data.PHONE || '';
                                    $scope.diaryData.CON_PHONE = response.result.attorney_data.PHONE || '';
                                    $scope.diaryData.LOCATION = response.result.attorney_data.LOCATION || '';
                                    $scope.diaryData.CONTACT = response.result.attorney_data.CONTACT || '';
                                    $scope.diaryData.claim_number_mask = response.result.attorney_data.claim_number_mask || '';
                                })
                                .error(function(err) {
                                    $rootScope.showLoader(false);
                                });
                        }, 100);
                    }
                    $rootScope.showLoader(false);
                }
                else if (newValue === 'our_suites') {
                    $rootScope.showLoader(true);
                    if ($scope.diaryData && $scope.diaryData.ToBeHeldAt) {
                        var id = $scope.diaryData.ToBeHeldAt;
                        commonFactory.get('/api/suites/' + id + '/edit')
                            .success(function(response) {
                                $rootScope.showLoader(false);
                                if (response.status == 200 && response.result && response.result.our_suites) {
                                    $scope.diaryData.HELD_AT1 = response.result.our_suites.held_at || '';
                                    $scope.diaryData.HELD_AT_CITY = response.result.our_suites.city || '';
                                    $scope.diaryData.HELD_AT_STATE = response.result.our_suites.state || '';
                                    $scope.diaryData.HELD_AT_ZIPCODE = response.result.our_suites.zipcode || '';
                                    $scope.diaryData.HA_PHONE = response.result.our_suites.phone_number || '';
                                    $scope.diaryData.HELD_AT2 = response.result.our_suites.address1 || '';
                                    $scope.diaryData.HELD_AT3 = response.result.our_suites.address2 || '';
                                    $scope.diaryData.CON_PHONE = '';
                                    $scope.diaryData.LOCATION = '';
                                    $scope.diaryData.CONTACT = '';
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                    $rootScope.showLoader(false);
                }
                else {

                    $scope.diaryData.HELD_AT1 = '';
                    $scope.diaryData.HELD_AT2 = '';
                    $scope.diaryData.HELD_AT3 = '';
                    $scope.diaryData.HELD_AT_CITY = '';
                    $scope.diaryData.HELD_AT_STATE = '';
                    $scope.diaryData.HELD_AT_ZIPCODE = '';
                    $scope.diaryData.HA_PHONE = '';
                    $scope.diaryData.CON_PHONE = '';
                    $scope.diaryData.LOCATION = '';
                    $scope.diaryData.CONTACT = '';

                    if(newValue === 'other'){
                        $scope.preventToInput = false;
                    }
                }
            }
        });

        //When select Held at our suites ACTION will automatically populate and data select.
        $scope.$watch('diaryData.ToBeHeldAt', function(newValue, oldValue) {
            if (newValue && newValue != 'undefined') {
                $rootScope.showLoader(true);
                $scope.diaryData.location_action = "our_suites";
                //if ($scope.diaryData && $scope.diaryData.ToBeHeldAt) {
                var id = newValue;
                commonFactory.get('/api/suites/' + id + '/edit')
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200 && response.result && response.result.our_suites) {
                            $scope.diaryData.HELD_AT1 = response.result.our_suites.held_at || '';
                            $scope.diaryData.HELD_AT_CITY = response.result.our_suites.city || '';
                            $scope.diaryData.HELD_AT_STATE = response.result.our_suites.state || '';
                            $scope.diaryData.HELD_AT_ZIPCODE = response.result.our_suites.zipcode || '';
                            $scope.diaryData.HA_PHONE = response.result.our_suites.phone_number || '';
                            $scope.diaryData.HELD_AT2 = response.result.our_suites.address1 || '';
                            $scope.diaryData.HELD_AT3 = response.result.our_suites.address2 || '';
                            $scope.diaryData.CON_PHONE = '';
                            $scope.diaryData.LOCATION = '';
                            $scope.diaryData.CONTACT = '';
                            $scope.diaryData.held_at_flag = parseInt(response.result.our_suites.held_at_flag) || '';
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
                //}
                $rootScope.showLoader(false);
            }
        });

        //Action fields needs to clear when other action is choose.
        function clearActionfield() {
            $scope.diaryData.HELD_AT1 = '';
            $scope.diaryData.HELD_AT2 = '';
            $scope.diaryData.HELD_AT3 = '';
            $scope.diaryData.HELD_AT_CITY = '';
            $scope.diaryData.HELD_AT_STATE = '';
            $scope.diaryData.HELD_AT_ZIPCODE = '';
            $scope.diaryData.HA_PHONE = '';
            $scope.diaryData.CON_PHONE = '';
            $scope.diaryData.LOCATION = '';
            $scope.diaryData.CONTACT = '';
        }

        $scope.clearOrdFirm = function(){
            if(!$scope.isSelectOrdFirm){ 
                $scope.diaryData.attorney_id = null;
                $scope.isConnectionMethodByFirm = false; 
            }
        };

        $scope.resetOrdFirm = function(){
            if($scope.isSelectOrdFirm){
                $scope.diaryData.attorney_id = null;
                $scope.isSelectOrdFirm = false;
                $scope.diaryData.firm_HELD_AT2 = '';
                $scope.diaryData.firm_HELD_AT3 = '';
                $scope.diaryData.firm_HELD_AT_CITY = '';
                $scope.diaryData.firm_HELD_AT_STATE = '';
                $scope.diaryData.firm_HELD_AT_ZIPCODE = '';
                $scope.diaryData.firm_HA_PHONE = '';
                $scope.diaryData.firm_CON_PHONE = '';
                $scope.diaryData.firm_LOCATION = '';
                $scope.diaryData.firm_CONTACT = '';
                $scope.diaryData.NOTES_1 = '';
                $scope.diaryData.NOTES_2 = '';
                $scope.diaryData.sort1 = '';
                $scope.diaryData.sort2 = '';
                $scope.diaryData.source_id = ''; 
                $scope.diaryData.LevelOfPayment = ''
                $scope.onselectdisable = false;
                $scope.diaryData.send_job_confirmation_on = [];
                $scope.firm_status = '';
                $scope.firm_beeper = '';
                $scope.ebt = [];
                $scope.diaryData.ebt_contact_id = null;
                $scope.job_called_in_by_list = [];
                $scope.diaryData.job_called_in_by = null;
                $scope.diaryData.reporting_sales_ids = [];
                $scope.diaryData.records_sales_ids = [];
                $scope.salesman_commission_type = '';
                $scope.diaryData.business_unit_id = null;
                $scope.diaryData.held_at_flag = null;
                $scope.insuranceRates = [];
            }
        };

        $scope.getInsuranceRateTable = function (attorney_id) {
            commonFactory.get('/api/rates')
                .success(function (response) {
                    if (response.result && response.result.rates) {
                        $scope.insuranceRates = response.result.rates;
                    }
                })
                .error(function (err) {
                });
        }
        $scope.getInsuranceRateTable();

        $scope.getRateTable = function(attorney_id){
            let params = {};
            params.attorneyId = attorney_id;

            commonFactory.getWithParams('/api/getFirmRateTable', params)
                .success(function(response) { 
                    if (response.result && response.result.rates) {
                        $scope.insuranceRates = response.result.rates;
                    }
                })
                .error(function(err) {});
        }

        $scope.onselectdisable = false;
        //After selecting Firm assign value to this field.
        $scope.onSelect = function($item, $model, $label) {
            $scope.isSelectOrdFirm = true;
            if(!$scope.foundFromElaw){

                // $scope.diaryData.HELD_AT1 = $item.NAME || ''; 
                $scope.orderingFirm = $item;
                $scope.diaryData.firm_HELD_AT2 = $item.ADDR_LINE1 || '';
                $scope.diaryData.firm_HELD_AT3 = $item.ADDR_LINE2 || '';
                $scope.diaryData.firm_HELD_AT_CITY = $item.CITY || '';
                $scope.diaryData.firm_HELD_AT_STATE = $item.STATE || '';
                $scope.diaryData.firm_HELD_AT_ZIPCODE = $item.ZIP || '';
                $scope.diaryData.firm_HA_PHONE = $item.PHONE || '';
                $scope.diaryData.firm_CON_PHONE = $item.PHONE || '';
                $scope.diaryData.firm_LOCATION = $item.LOCATION || '';
                $scope.diaryData.firm_CONTACT = $item.CONTACT || '';
                $scope.diaryData.NOTES_1 = $item.NOTES_1 || '';
                $scope.diaryData.NOTES_2 = $item.NOTES_2 || '';
                $scope.diaryData.sort1 = $item.Sort1 || '';
                $scope.diaryData.sort2 = $item.Sort2 || '';
                $scope.diaryData.source_id = $item.source_id || ''; 
                $scope.diaryData.LevelOfPayment = $item.LevelOfPayment;
                $scope.diaryData.turn_around_info = $item.turn_around_days;
                $scope.generalData.turn_around_info = $item.turn_around_days;
                $scope.firm_status = $item.STATUS;
                $scope.salesman_commission_type = $item.salesman_commission_type;
                $scope.diaryData.business_unit_id = $item.businessunit.id || '';
                if($item.businessunit.di_job_summary == "1"){
                   $scope.diaryData.di_job_summary = $item.di_job_summary
                }
                // $scope.diaryData.translator_id = $item.translator_id;

                angular.forEach($scope.auto_partners,function(value, key){
                    if(value.code == $item.BEEPER){                
                        $scope.firm_beeper = value.text;
                    }
                });
                
                $scope.onselectdisable = true; //Onselect Ordering Firm disable fields.
                
                // check firm comission type
                if($item.reporting_salesman.length){
                    $scope.reportingSalesmanIds = [];
                    angular.forEach($item.reporting_salesman,function(value, key){
                        $scope.reportingSalesmanIds[key] = value.sales_reptyp_id;
                    });
                    if($item.salesman_commission_type!='CIP'){
                     $scope.diaryData.reporting_sales_ids = $scope.reportingSalesmanIds;
                    }
                }

                if($item.records_salesman.length){
                    var recordsSalesmanIds = [];
                    angular.forEach($item.records_salesman,function(value, key){
                        recordsSalesmanIds[key] = value.sales_reptyp_id;
                    });
                    $scope.diaryData.records_sales_ids = recordsSalesmanIds;
                }

                // $scope.getRateTable($item.id);

                $http.post(apiUrl+'/api/get-job-called-in-by-list', { attorney_id : $item.id }).success(function(response){
                    $scope.job_called_in_by_list = response.result.data;
                    if($scope.job_called_in_by_list){
                        var i = 0;
                        var emails = [];
                        // If send job confirmation type selected to multiple then and then only read attorney(employee of firm) emails.And those should have entry in auth_user table and also have active ='Y' and send_job_confirmation = 'Y'.
                        if($item.send_job_confirmation_to == 'multiple' &&  $scope.job_called_in_by_list.dep_attorney && $scope.job_called_in_by_list.dep_attorney.length > 0){
                            angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){
                                if(value.email){
                                    emails[i] = value.email;
                                    i++;
                                }
                            });
                        }
                        $scope.diaryData.send_job_confirmation_on =  emails;

                        //CHANGE AS PER FRANK Account Executive GET EVERY TIME EMAIL FROM BACKEND
                        // If Account Executive email exist in auth user table with active and job confirmation = 'Y' flag.
                        // if($scope.job_called_in_by_list.sales_auth_user){ 
                        //     angular.forEach($scope.job_called_in_by_list.sales_auth_user, function(value, key){
                        //         if(value.email){
                        //             emails[i] = value.email;
                        //             i++;
                        //         }
                        //     });
                        // }
                        
                        
                    }
                });
                $http.post(apiUrl+'/api/get-attorney-list', { attorney_id : $item.id }).success(function(response){
                    $scope.attornay_list = response.result.data;
                });
                            
                var data = {};
                data.firm_id = $item.id;

                $timeout(function() {
                    commonFactory.post('/api/attorneybyfirm', data)
                        .success(function(response) {
                            if (response) {
                                if (response && response.result && response.result.attorney_data) {
                                    $scope.attorney = response.result.attorney_data || '';
                                }
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });
                }, 1000);

                $timeout(function() {
                    commonFactory.post('/api/ebtbyfirm', data)
                        .success(function(response) {
                            if (response) {
                                if (response && response.result && response.result.ebt_data) {
                                    $scope.ebt = response.result.ebt_data || '';
                                }
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });
                }, 1000);
                $scope.getJobDefaults();
            } else{
                swal({
                    title: "Are you sure?",
                    text: "You are about to add new firm!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        $scope.diaryData.attorney_id = null;
                        var url = $state.href('firms.add.step_one_add', { elawId : $item.id});
                        window.open(url,'_blank');
                    }
                    else{
                        $scope.diaryData.attorney_id = null;
                        $scope.$apply();
                    }

                });
            }
            if($item && $item.id) {
                commonFactory.get('/api/attorneys/' + $item.id + '/edit')
                    .success(function(response) {
                        $scope.isConnectionMethodByFirm = false; 
                        if(response.result.data.client_held_at_flag != null && response.result.data.client_held_at_flag != ''){
                            $scope.diaryData.held_at_flag = response.result.data.client_held_at_flag;
                            $scope.isConnectionMethodByFirm = true;
                        }
                    }).error(function(err) {
                    });
            }
        };

        // work here for interpreter
        $scope.handleInterpreterAction = function(id,translator_id){
            if(translator_id !=null){
                 $scope.diaryData.translator_id = translator_id;
            }else{
                if($scope.diaryData.language && $scope.diaryData.language.length >= 2){

                    $http.get(apiUrl + '/api/get_translator',{
                        params: {
                            attorneyId: id
                        }
                    }).then(function(response) {            
                        $scope.diaryData.translator_id = response.data.result.translator ?? null;            
                     }).catch(function(error){
                        console.error("Error fetching translator:", error);
                     }); 
                }
            }
        }

        //In ACTION WHERN SELECT DIFFERENT LOCATION POPULATE THIS FIELD.
        $scope.onSelectHeldat = function($item, $model, $label) {
            $scope.diaryData.HELD_AT2 = $item.ADDR_LINE1 || '';
            $scope.diaryData.HELD_AT3 = $item.ADDR_LINE2 || '';
            $scope.diaryData.HELD_AT_CITY = $item.CITY || '';
            $scope.diaryData.HELD_AT_STATE = $item.STATE || '';
            $scope.diaryData.HELD_AT_ZIPCODE = $item.ZIP || '';
            $scope.diaryData.HA_PHONE = $item.PHONE || '';
            $scope.diaryData.CON_PHONE = $item.PHONE || '';
            $scope.diaryData.CONTACT = $item.CONTACT || '';
        };

        $scope.refreshCallBy = function(id){
            $http.post(apiUrl+'/api/get-job-called-in-by-list', { attorney_id : id }).success(function(response){
                $scope.job_called_in_by_list = response.result.data;
               
            });
        }

        $scope.refreshAttorney = function(id){
            $http.post(apiUrl+'/api/get-attorney-list', { attorney_id : id }).success(function(response){
                $scope.attornay_list = response.result.data;
            });
        }
        $scope.indexData = function(val) {
            return $http.get(apiUrl + '/api/caseByIndex', {
                params: {
                    index: val
                }
            }).then(function(response) {

                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.onSelectIndex = function($item, $model, $label){
            $scope.diaryData.IndexNumber = $item.IndexNumber;
            $scope.diaryData.CAPTION = $item.CAPTION.trim();
        };

        $scope.zoomGenrate = function(isValid){
            $scope.isZoomLink = true;
            $scope.saveDiarydata(isValid)
        };

        $scope.saveDiarydata = function(isValid,redirect_to_payment,moveToGeneralInformation,redirectToNewCase, saveAsWaitList) {
            if (isValid) {
                //show alert box if job confirmation email field is blank.
                if(!$scope.diaryData.job_called_in_by){
                    swal({
                        title: "Are you sure want to save?",
                        text: "you haven't selected job called in by!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#104a7b",
                        confirmButtonText: "Yup, save it!",
                        cancelButtonText: "Oops, cancel it!"
                    }).then((result) => {
                        if (!result.isConfirmed) {
                            return false;
                        }else{
                            $scope.jobStausCheckpoint(redirect_to_payment, moveToGeneralInformation, redirectToNewCase,saveAsWaitList);
                        }
                    });
                }else{
                    $scope.jobStausCheckpoint(redirect_to_payment, moveToGeneralInformation, redirectToNewCase, saveAsWaitList);
                }
            } else {
                $scope.formSubmit =true
                swal('Error!', 'Please enter all required fields.', 'error');
            }
        };

        $scope.jobStausCheckpoint = function(redirect_to_payment, moveToGeneralInformation, redirectToNewCase, saveAsWaitList){
            let jobdate = moment($scope.diaryData.DATE_TAKEN).format('YYYY-MM-DD');
            let dayIncrement = 1;

            if(moment().day() == 5){
                dayIncrement = 3;
            }else if(moment().day() == 6){
                dayIncrement = 2;
            }

            let todaydate = moment().format('YYYY-MM-DD');
            let nextBusinessDay  = moment().add(dayIncrement, 'days').format('YYYY-MM-DD');
            if(saveAsWaitList){
                $scope.diaryData.job_status = 'save_as_wait';
                $scope.saveDiaryDataAfterConfirmation(redirect_to_payment, moveToGeneralInformation, redirectToNewCase);
            }else if(jobdate == nextBusinessDay || jobdate == todaydate){
                $scope.diaryData.job_status = null;
                var btn = "button";
                swal({
                    title: "PLEASE SELECT THE STATUS FOR THE JOB.",
                    text: '<button type="' + btn + '" id="btnScheduled" >Scheduled</button> ' +
                    '<button type="' + btn + '" id="btnPending" >Pending Confirmation</button> ' +
                    '<button type="' + btn + '" id="btnConfirmed" >Confirmed</button>',
                    html: true,
                    showConfirmButton: false
                });
                 
                $(document).off('click', "#btnScheduled");
                $(document).on('click', "#btnScheduled", function() {
                    if(!$scope.diaryData.job_status){
                        $scope.diaryData.job_status = 'schedule';
                        $scope.saveDiaryDataAfterConfirmation(redirect_to_payment, moveToGeneralInformation, redirectToNewCase);
                    }
                });
                  
                $(document).off('click', "#btnPending");
                $(document).on('click', "#btnPending", function() {
                    if(!$scope.diaryData.job_status){
                        $scope.diaryData.job_status = 'pending';
                        $scope.saveDiaryDataAfterConfirmation(redirect_to_payment, moveToGeneralInformation, redirectToNewCase);
                    }
                });
                
                $(document).off('click', "#btnConfirmed");
                $(document).on('click', "#btnConfirmed", function() {
                    if(!$scope.diaryData.job_status){
                        $scope.diaryData.job_status = 'confirmed';
                        $scope.saveDiaryDataAfterConfirmation(redirect_to_payment, moveToGeneralInformation, redirectToNewCase);
                    }
                   
                });
                 
                

            //     swal({
            //         title: 'PLEASE SELECT THE STATUS FOR THE JOB.',
            //         width: '45em',
            //         allowOutsideClick: false,
            //         showDenyButton: true,
            //         showCancelButton: true,
            //         confirmButtonText: 'Scheduled',
            //         denyButtonText: `Pending Confirmation`,
            //         cancelButtonText: `Confirmed`,
            //       }).then((result) => {
            //         if (result.isConfirmed) {
            //           $scope.diaryData.job_status = 'schedule';
            //         } else if (result.isDenied) {
            //           $scope.diaryData.job_status = 'pending';
            //         }else{
            //           $scope.diaryData.job_status = 'confirmed';
            //         }
            //         $scope.saveDiaryDataAfterConfirmation(redirect_to_payment, moveToGeneralInformation, redirectToNewCase);
            //     });
            }else{
                $scope.saveDiaryDataAfterConfirmation(redirect_to_payment, moveToGeneralInformation, redirectToNewCase);
            }
        }
        $scope.calculateJobDuration = function() {
            if ($scope.diaryData.TIME_TAKEN && $scope.diaryData.End_Time) {
                const startTime = moment($scope.diaryData.TIME_TAKEN).format("HH:mm");
                const endTime = moment($scope.diaryData.End_Time).format("HH:mm");
                
                var start = moment(startTime, "HH:mm");
                var end = moment(endTime, "HH:mm");
                
                const startTimeMoment = moment(start);
                const endTimeMoment = moment(end);
                
                const differenceInMinutes = endTimeMoment.diff(startTimeMoment, 'minutes');
                $scope.endTimeError=false;
                
                if (differenceInMinutes <= 0 && $scope.diaryData.End_Time !=null) {
                    $scope.endTimeError= 'Must greater than Job time';
                    return;
                }
                const differenceHours = Math.floor(differenceInMinutes / 60);
                const differenceMinutes = differenceInMinutes % 60;
                
                $scope.diaryData.JOB_LENGTH= differenceHours+'h:'+differenceMinutes+'m';

            } else {
                $scope.diaryData.JOB_LENGTH = '';
            }
        };
        $scope.saveDiaryDataAfterConfirmation = function(redirect_to_payment,moveToGeneralInformation,redirectToNewCase){
            $rootScope.showLoader(true);
            var diaryData = angular.fromJson(angular.toJson($scope.diaryData));

            diaryData.attorney_id = diaryData.attorney_id ? diaryData.attorney_id.id : null;
            diaryData.ATTORNEY = $scope.diaryData.attorney_id ? $scope.diaryData.attorney_id.NAME_KEY : '';
            diaryData.otherfirmlocation = diaryData.otherfirmlocation ? diaryData.otherfirmlocation.id : null;
            diaryData.LOSS_DT = diaryData.LOSS_DT ? moment(diaryData.LOSS_DT).format('MM/DD/YYYY') : null;
            diaryData.DATE_TAKEN = moment(diaryData.DATE_TAKEN).format('MM/DD/YYYY');
            diaryData.dte_taken  = moment(diaryData.DATE_TAKEN).format('YYYY-MM-DD');

            diaryData.TIME_TAKEN = moment(diaryData.TIME_TAKEN).format('hh:mm A');

            if(diaryData.End_Time){
                diaryData.End_Time = moment(diaryData.End_Time).format('hh:mm A');
            }
            //check zoom link create
            if($scope.isZoomLink){
                diaryData.CreateZoomLink = true;

            }
            //diaryData.DATE_TAKEN = moment(diaryData.DATE_TAKEN).format('MM/DD/YYYY');

            //diaryData.DATE_ADDED = moment(diaryData.DATE_ADDED).format('MM/DD/YYYY');
            if(diaryData.DUE_DATE){
            diaryData.DUE_DATE = moment(diaryData.DUE_DATE).format('MM/DD/YYYY');
            }
            //diaryData.LAST_DATE = moment(diaryData.LAST_DATE).format('MM/DD/YYYY');
            //diaryData.LAST_CHGD = moment(diaryData.LAST_CHGD).format('MM/DD/YYYY');
            diaryData.CNCL_DATE = moment(diaryData.CNCL_DATE).format('MM/DD/YYYY');

            diaryData.OTH_ATTY1 = diaryData.OTH_ATTY1 ? diaryData.OTH_ATTY1.id : null;
            diaryData.OTH_ATTY2 = diaryData.OTH_ATTY2 ? diaryData.OTH_ATTY2.id : null;
            diaryData.OTH_ATTY3 = diaryData.OTH_ATTY3 ? diaryData.OTH_ATTY3.id : null;
            diaryData.OTH_ATTY4 = diaryData.OTH_ATTY4 ? diaryData.OTH_ATTY4.id : null;
            diaryData.OTH_ATTY5 = diaryData.OTH_ATTY5 ? diaryData.OTH_ATTY5.id : null;
            diaryData.location_action = diaryData.location_action ? diaryData.location_action : 'sync_data'; //defaut action is other
            diaryData.CLM_NOTE = $scope.generalData.CLM_NOTE;

            if (diaryData.location_action && diaryData.location_action == "diff_location") {
                diaryData.HELD_AT1 = diaryData.HELD_AT1 ? diaryData.HELD_AT1.NAME : null;
            }

            var i = 0;
            angular.forEach($scope.diaryData.send_job_confirmation_on,function(value, key){
                diaryData.send_job_confirmation_on[i] = value.text;
                i++;
            });
            

            diaryData.translator_id = diaryData.translator_id ? diaryData.translator_id : null;
            diaryData.translator_time = diaryData.translator_time ? diaryData.translator_time : null;
            diaryData.business_unit_id = diaryData.business_unit_id ? diaryData.business_unit_id : null;
            diaryData.held_at_flag = diaryData.held_at_flag ? diaryData.held_at_flag : null;
            if(!diaryData.turn_around_info){
                diaryData.turn_around_info = 10;
            }
            diaryData.DUE_DATE = moment(diaryData.DATE_TAKEN, 'MM/DD/YYYY').businessAdd(diaryData.turn_around_info)._d;
            diaryData.DUE_DATE=moment(diaryData.DUE_DATE).format('MM/DD/YYYY');

            if (diaryData.translator_time) {
                diaryData.translator_time = moment(diaryData.translator_time).format('hh:mm A');
            }

            commonFactory.post('/api/diary', diaryData)
                .success(function(response) {
                    if(response.error){
                        Notification.error(response.result.message);
                    }
                    if (response && response.result && response.result.diary) {
                        $rootScope.showLoader(false);
                        Notification.success(response.result.message);
                        if(redirectToNewCase ==true){ 
                            $state.go('diary.addcase.step_one');
                        }
                        else if($scope.goToHome == true){ 
                            $state.go('index.home');
                        }else if(moveToGeneralInformation){ 
                            $state.go("diary.editcase.step_two", {id: response.result.diary.JOB_NO}); // Here state params is JOB id
                        }
                        else if(redirect_to_payment){ 
                            $state.go('payroll.add_payment',{job_id:response.result.diary.JOB_NO});
                        }else{ 
                            $state.go("diary.editcase.step_one", { id: response.result.diary.JOB_NO ? response.result.diary.JOB_NO : '' });
                            // $state.go("diary.editcase.step_one", { id: response.result.diary.JOB_NO ? response.result.diary.JOB_NO : '' });
                        }

                    } else {
                        $rootScope.showLoader(false);
                        if($scope.goToHome == true){
                            $state.go('index.home');
                        }
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                    console.log(err);
                });
        }
        $scope.invoicelist = function(val) {
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val,
                    legacy_search:true,
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.search_btn_disabled = false;
        $scope.findfirm = {};
        $scope.gotoJob = function($item, $model, $label){
            $scope.search_btn_disabled = true;
            $state.go("diary.editcase.step_one", { id: $item.JOB_NO ? $item.JOB_NO : '' });
        };

        $scope.goToEditJob = function(){
            if($scope.findfirm.JOB_NO && $scope.findfirm.JOB_NO.JOB_NO){
                $state.go('diary.editcase.step_one',{id : $scope.findfirm.JOB_NO.JOB_NO });
            }
        };

        $scope.changeJobNumber = function(){

            if($scope.search_btn_disabled){
                $scope.findfirm.JOB_NO = '';
                $scope.search_btn_disabled = false;
            }

        };

        $scope.onSelectOtherSideFirm = function($item, $model, $label){
            $scope.otherSideFirmSelected = true;

            if($scope.foundFromElaw){
                swal({
                    title: "Are you sure?",
                    text: "You are about to add new firm!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        $scope.diaryData.otherfirmlocation = null;
                        $scope.$apply();
                        var url = $state.href('firms.add.step_one_add', { elawId : $item.id});
                        window.open(url,'_blank');
                    }else{
                        $scope.diaryData.otherfirmlocation = null;
                        $scope.$apply();

                    }
                });
            }
        };

        $scope.unSelectOtherSideFirm = function(){
            $scope.otherSideFirmSelected = false;
        };

        $scope.onselectHeldAtLocation = function(){
            if($scope.diaryData.ToBeHeldAt > 1){
                $scope.heldAtLocationSelected = true;
            }
            else{
                $scope.heldAtLocationSelected = true;
            }
        };


        $(window).bind('keydown', function(event) {
            $scope.goToHome = false;

            if (event.ctrlKey || event.metaKey) {

                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        if($state.current.name == "diary.addcase.step_one"){
                            $scope.formSubmit = true;
                            $scope.saveDiarydata($scope.diaryForm.$valid);
                        }

                        $scope.$apply();
                        break;

                    case 'g':
                        event.preventDefault();

                        if($state.current.name == "diary.addcase.step_one"){
                            $scope.saveDiarydata($scope.diaryForm.$valid, false,true);
                        }

                        $scope.formSubmit = true;
                        $scope.$apply();
                        break;

                    case 'p':
                        event.preventDefault();

                        if($state.current.name == "diary.addcase.step_one"){
                            $scope.saveDiarydata($scope.diaryForm.$valid, true);
                        }

                        $scope.$apply();
                        break;

                    case 'd':
                        event.preventDefault();

                        if($state.current.name == "diary.addcase.step_one"){
                            $scope.goToHome = true;
                            $scope.saveDiarydata($scope.diaryForm.$valid);
                        }

                        $scope.formSubmit = true;
                        $scope.$apply();
                        break;

                }
            }
        });


        $scope.checkJobNumberExist = function(data){
            var d = $q.defer();
            if(data){
                $http.get(apiUrl + '/api/check-job-exist', {
                    params: {
                        JOB_NO: data
                    }
                }).then(function (response) {
                    if (response.data.result) {
                        d.reject("Sorry, this job number already generated");
                    }
                    else{
                        $scope.JOB_NO = data;
                        d.resolve();
                    }
                }).then(function(){
                    d.reject('Server error!');
                });
            }
            else{
                d.reject('Please enter the job number');
            }
            return d.promise;
        };

        $scope.addUser= function (attorney_id) {
                var Modal = $uibModal.open({
                templateUrl: "modules/diary/addUser/add_user.html",
                controller: 'AddUserCallByControllers',
                resolve: {
                    attorney_id: function () {
                        return attorney_id;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        $scope.copyValueScheduledBy = '';
        $scope.ScheduledBySelect = function(id){
            let email = '';
            if($scope.job_called_in_by_list){
                angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){
                    if(value.attorney_no == id){
                        if(value.email){
                           email = value.email.replace(/;/g,',');
                        }
                    }
                });
            }
            
            $scope.copyValueScheduledBy = email;
        }

        $scope.copyValueAttorney = '';
        $scope.AttorneySelect = function(id){
            let email = '';
            if($scope.attornay_list){
                angular.forEach($scope.attornay_list.dep_attorney,function(value, key){
                    if(value.attorney_no == id){
                        if(value.email){
                           email = value.email.replace(/;/g,',');
                        }
                    }
                });
            }
            
            $scope.copyValueAttorney = email;
        }

        $scope.copyText = function(text){
            navigator.clipboard.writeText(text);
            Notification.success('Email Copied!');
        }

        $scope.onSelectCalledInBy = function(called_in_by_id){
            var attorney_data = $scope.job_called_in_by_list.dep_attorney.find(o => o.attorney_no === called_in_by_id);
            console.log(attorney_data,"data");
            if($scope.diaryData.di_job_summary == "1"){
                $scope.diaryData.di_job_summary = attorney_data.di_job_summary
            }
            // If send job confirmation type selected to single then and then only we listing job called in by emails. Only one email can be selected of them. 
            if($scope.orderingFirm && ($scope.orderingFirm.send_job_confirmation_to == 'single' || $scope.orderingFirm.send_job_confirmation_to == null)){
               
                // On select any attorney from list we are going to remove all selected emails of old attorney
                angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){              
                    var emails = [];
                    if(value.email){
                        emails = value.email.replace(/;/g,',').split(',');
                        angular.forEach(emails,function(value, key){
                            $scope.diaryData.send_job_confirmation_on = $scope.diaryData.send_job_confirmation_on.filter(function(item){
                                return item.text != value;
                            });         
                        });
                    }
                });
                

                // Add email address of selected attorney if exists.If already exists then nothing to do.
                if(called_in_by_id){
                    angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){
                        if(value.attorney_no == called_in_by_id){
                            var emails = [];
                            if(value.email){
                               emails = value.email.replace(/;/g,',').split(',');
                            }

                            angular.forEach(emails,function(email, key){
                                var is_email_exists = false; 
                                $scope.diaryData.send_job_confirmation_on.filter(function(item){
                                    if(item.text == email){
                                        is_email_exists = true;
                                    }
                                });
                                if(!is_email_exists){
                                    $scope.diaryData.send_job_confirmation_on.push({text: email});
                                } 
                            });
                             
                        }
                    });
                }
            }
            
            var indexNo = 0
            var isSalesman = false
            angular.forEach($scope.job_called_in_by_list.dep_attorney,function(value, key){              
               
                 if($scope.salesman_commission_type == 'CIP' && value.commission_salesman_id && value.attorney_no == called_in_by_id){
                    $scope.diaryData.reporting_sales_ids = [];
                    isSalesman = true
                     $scope.diaryData.reporting_sales_ids.push(value.commission_salesman_id);
                 }
                 indexNo++
               
                 if($scope.salesman_commission_type == 'CIP' && isSalesman == false && indexNo == $scope.job_called_in_by_list.dep_attorney.length ){
                
                    $scope.diaryData.reporting_sales_ids = $scope.reportingSalesmanIds
                 }
            });
            
            $scope.ScheduledBySelect(called_in_by_id);
        }
        $scope.formatLabel = function (firm) {
            if(firm)
            {
                var label = firm.NAME +' | ('+ firm.PHONE +') | '+ firm.ADDR_LINE1 +' '+ firm.ADDR_LINE2 +' | '+ firm.CITY +' | '+ firm.source.name +' '+ firm.source.name_2 +' | YTD BILLED ('+ $scope.currencyFormat(firm.YTD_BILLED) + ')';

                if(firm.businessunit)
                {
                    label = label + ' | '+firm.businessunit.name;
                }

                return label;
            }
        };
        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }else if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }else{
                return '$'+ '0.00';
            }
        };

        $scope.$watch('diaryData.DATE_TAKEN', function (newValue, oldValue) {
            if (newValue && newValue._isAMomentObject ) {
               
               $scope.isJobDateChange = true;
            }
        });
        $scope.$watch('diaryData.business_unit_id', function (newValue, oldValue) {
            if (newValue && newValue != 'undefined') {
               $scope.getJobDefaults();
            }
        });

        $scope.getJobDefaults = function (){
            $rootScope.showLoader(true);
            var bu_id = $scope.diaryData.business_unit_id;
            commonFactory.get('/api/businessunit/' + bu_id + '/edit')
            .success(function (response) {
                $rootScope.showLoader(false);
                if (response.status == 200 && response.result && response.result.business_unit_detail) {
                    if(response.result.business_unit_detail.location_action ){
                        if(
                        (response.result.business_unit_detail.location_action=='attorneyfirm' && $scope.diaryData.attorney_id ) ||
                        (response.result.business_unit_detail.location_action=='our_suites' && $scope.diaryData.ToBeHeldAt) ||
                        (response.result.business_unit_detail.location_action=='heldatfirm' && $scope.diaryData.otherfirmlocation) ||
                        (response.result.business_unit_detail.location_action!='attorneyfirm' && 
                         response.result.business_unit_detail.location_action!='our_suites' &&
                         response.result.business_unit_detail.location_action!='heldatfirm')
                        ){
                        $scope.diaryData.location_action = response.result.business_unit_detail.location_action || '';
                        }
                    }
                    if(response.result.business_unit_detail.held_at_flag && !$scope.isConnectionMethodByFirm){
                        $scope.diaryData.held_at_flag = response.result.business_unit_detail.held_at_flag || '';
                    }
                    if(response.result.business_unit_detail.location_id){
                        $scope.diaryData.location_id = response.result.business_unit_detail.location_id || '';
                    }
                    if(response.result.business_unit_detail.job_category_id){
                        $scope.diaryData.job_category_id = response.result.business_unit_detail.job_category_id || '';
                    }
                    if(response.result.business_unit_detail.case_type_id){
                        $scope.diaryData.case_type_id = response.result.business_unit_detail.case_type_id || '';
                    }
                    if(response.result.business_unit_detail.witness_type_id){
                        $scope.diaryData.witness_type_id = response.result.business_unit_detail.witness_type_id || '';
                    }
                    if(response.result.business_unit_detail.turn_around_info){
                        $scope.diaryData.turn_around_info = response.result.business_unit_detail.turn_around_info || '';
                    }
                    if(response.result.business_unit_detail.job_date_business_day >0 && !$scope.isJobDateChange){
                        $scope.diaryData.DATE_TAKEN = moment().businessAdd(response.result.business_unit_detail.job_date_business_day)._d;
                    }
                    else{
                        if(!$scope.isJobDateChange){
                            $scope.diaryData.DATE_TAKEN = moment().businessAdd(1)._d;
                        }
                    }
                    $scope.diaryData.di_job_summary = response.result.business_unit_detail.di_job_summary;
                   
                }
            })
            .error(function (err) {
                $rootScope.showLoader(false);
            });
        }

    }]);
