/**
 * Deitz court reporting
 *
 */

/**
 * MainCtrl - controller
 */
function MainCtrl($rootScope, $scope, loginFactory, $http, $state, $cookies, notify, Notification, $location, $timeout, $localStorage, $uibModal, invoiceFactory,$window,$uibModalStack,UsersFactory,apiUrl, payrollFactory, SweetAlert,Idle, Title, commonFactory, $sce,TextmessagesFactory,cashReceiptFactory) {

    this.userName = 'Example user';
    this.helloText = 'Welcome in SeedProject';
    this.descriptionText = 'It is an application skeleton for a typical AngularJS web app. You can use it to quickly bootstrap your angular webapp projects and dev environment for these projects.';

    var forceSSL = function () {
        if ($location.protocol() !== 'https' && $location.host() !== 'localhost' && $location.host() !== 'staging.deitzreporting.com') {
            $window.location.href = $location.absUrl().replace('http', 'https');
        }
    };
    forceSSL();

    $scope.isSandbox = false;
    $scope.isBeta = false;
    $scope.isLocalhost = false;
    $scope.envTitle = "Local";
    // Assigning deposition based on deitz host.
    if($location.host() == 'acceptance.deitzreporting.com'){
        $rootScope.depoHost = "https://www.depositionnet.com";
    }else if($location.host() == 'sandbox.deitzreporting.com'){
        $rootScope.depoHost = "https://sandbox.depositionnet.com";
        $scope.isSandbox = true;
        $scope.envTitle = "Sandbox";
    }else if($location.host() == 'dev.deitzreporting.com'){
        $rootScope.depoHost = "https://sandbox.depositionnet.com";
        $scope.isSandbox = true;
        $scope.envTitle = "Sandbox - Dev";
    }else if($location.host() == 'preview.deitzreporting.com'){
        $rootScope.depoHost = "https://preview.depositionnet.com";
        $scope.isSandbox = true;
        $scope.isLocalhost = true;
        $scope.envTitle = "Preview";
    }else if($location.host() == 'php8.deitzreporting.com'){
        $rootScope.depoHost = "https://www.depositionnet.com";
        $scope.isSandbox = true;
        $scope.envTitle = "PHP8";
    }else if($location.host() == 'beta.deitzreporting.com'){
        $rootScope.depoHost = "https://beta.depositionnet.com";
        $scope.isBeta = true;
        $scope.envTitle = "BETA";
    }else{
        $rootScope.depoHost = "http://localhost/deitz";
        $scope.isLocalhost = true;
    }
    $rootScope.showingBatchBouncingCounter = false;
    $rootScope.user_timezone = 'America/New_York';
    $scope.isScreenLock = false;

    $rootScope.env = localStorage.getItem('environment');
    // Initialize the batch
    $rootScope.batchInvoiceArray = $localStorage.batchInvoiceArray || [];
    $rootScope.batchInvoiceDisplayArray = $localStorage.batchInvoiceDisplayArray || [];
    $rootScope.textMessageUnreadArray = $localStorage.textMessageUnreadArray || []; 

    $rootScope.getBatchInvoices = function(){
         $http.get(apiUrl+'/api/invoice/get-batch-invoices').success(function(response){
            $rootScope.batchInvoiceDisplayArray = response.result;
        });
    };

    $rootScope.getUserTimeZone = function(){
        $http.get(apiUrl+'/api/users/get-user-time-zone').success(function(response){
            if(response.timezone){
                $rootScope.user_timezone_short_name = response.timezone.short_name || '';
                $rootScope.user_timezone = response.timezone.zoom_value || '';
            }else{
                $rootScope.user_timezone_short_name = '';
                $rootScope.user_timezone = '';
            }
        });
    };
    
    // This will hold loggedIn user information & would be available globally.
    if ($cookies.get("authUser")) {
        $scope.Authuser = JSON.parse($cookies.get("authUser"));
        // $rootScope.getBatchInvoices();
        // $rootScope.getUserTimeZone();
    }

    // This will hold audioperson login information.
    if ($cookies.get("audioperson")) {
        $rootScope.audioperson = JSON.parse($cookies.get("audioperson"));
    }

    $rootScope.hideCloseTabButton = false;
    if (history.length == 1) {  // Um, needs to be 0 for IE, 1 for Firefox
        // This is a new window or a new tab.
        $rootScope.hideCloseTabButton = true;
    }

    $rootScope.round_to_decimal_number = 2;

    $rootScope.state = $state;
    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        
        $rootScope.changeFavIcon();
        $window.scrollTo(0, 0);
        $rootScope.curState = toState;
        $rootScope.showLoader(false);
        if ($rootScope.state.current.name != "login" && $cookies.get('authUser')) {
            $rootScope.auth_user = JSON.parse($cookies.get('authUser'));
            $rootScope.getUserTimeZone();
            Idle.watch();
        }
        
        if(fromState.name == 'login' && toState.name!= '503' && toState.name!= 'forgot_password' && toState.name!= 'login_saml'){
            if($rootScope.batchInvoiceArray.length > 0){
                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!                
            }           
        }       
            
        $rootScope.$broadcast('textmessageCountBroadcastEvent', $rootScope.textMessageUnreadArray); // going down!                

        if($rootScope.state.current.name == 'login'){
            Idle.unwatch();
        }
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
        $rootScope.showLoader(true);
    });
    $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
        $scope.BusinessUnitsList = response.data.result.data;  
    });


    $rootScope.actionOnBatch = function () {

        if ($rootScope.batchInvoiceArray.length) {
            swalExtend({
                swalFunction: function () {
                    swal({
                            title: "Choose an action",
                            text: "Please click on your action for batch",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Clear Batch",
                            closeOnConfirm: false,
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                $rootScope.batchInvoiceArray = [];
                                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent',''); // going down!
                                swal("Batch Cleared!");
                            }
                        });
                },
                hasCancelButton: true,
                buttonNum: 1,
                hasCallback: false,
                buttonColor: ["#09375f"],
                buttonNames: ["Print Invoice"],
                clickFunctionList: [
                    function () {
                        const invoiceModal = $uibModal.open({
                            templateUrl: "modules/invoice/view_popup.html",
                            controller: 'viewPrintInvoiceController',
                            resolve: {
                                invoice_id: function () {
                                    return $rootScope.batchInvoiceArray;
                                },
                                loadPlugin: function ($ocLazyLoad) {
                                    return $ocLazyLoad.load([
                                        {
                                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                                        }
                                    ]);
                                }
                            },
                            windowClass: 'full-width-model',
                            keyboard: false,
                            backdrop: false,
                            size: 'lg',
                            close: function () {

                            }
                        });


                        invoiceModal.result.then(function (is_invoice_printed) {
                            //console.log(is_invoice_printed,invoices);
                            if (is_invoice_printed) {
                                var data = {invoice_ids: $rootScope.batchInvoiceArray};
                                // need to update the flag for print
                                invoiceFactory.post('/api/invoice/mark-as-printed', data).success(function (response) {
                                    //console.log(response);
                                    $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent',''); // going down!
                                }).error(function () {

                                });
                            }
                        }, function () {
                            console.log("Modal Dismissed!!!");
                        });
                    }
                ]
            });
        }
        else{

        }
    }

    var textTime;
    $scope.setUserTimezone = function () {

        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl: "modules/resources/templates/timezone_edit_template.html",
            controller: ['$scope', '$uibModalInstance', 'Notification', 'apiUrl', '$http', 'configFactory', function ($scope, $uibModalInstance, Notification, apiUrl, $http, configFactory) {

                $rootScope.showLoader(false);
                $scope.isSubmitted = false;
                $scope.uibModalInstance = $uibModalInstance;
                $scope.timezone_list = [];
                $scope.user_timezone = $rootScope.auth_user.timezone;

                $http.get(apiUrl+'/api/users/get-timezone-list').success(function(response){
                    $scope.timezone_list = response.timezone_list;
                });

                $scope.formData = {
                    user_timezone_id : $scope.user_timezone,
                    user_id : $rootScope.auth_user.id
                };
                
                $scope.save = function(isValid){
                    $scope.isSubmitted = true;
                    if(isValid){
                        $rootScope.showLoader(true);
                        configFactory.updateUserTimezone($scope.formData).then(function (response) {
                            
                            $rootScope.showLoader(false);
                            if(response.data.error == true){
                                Notification.error(response.data.result.message);
                            }else{
                                Notification.success(response.data.result.message);
                                var temp_user = {};
                                temp_user = $cookies.get('authUser');
                                temp_user = JSON.parse(temp_user);
                                
                                if(temp_user && temp_user.timezone)
                                {
                                    temp_user.timezone = response.data.result.user.timezone;
                                    $cookies.put('authUser', JSON.stringify(temp_user));
                                    $rootScope.auth_user = temp_user;
                                    $rootScope.getUserTimeZone();
                                }

                                $uibModalInstance.close();
                            }

                        },function (error) {
                            Notification.error(error || "Something went wrong.");
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }],
            keyboard: false,
            backdrop: false
        });

        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    };

    $scope.authGoldenRecordMergeAttorney = function(){  
        if($rootScope.havePermission('allow_access_without_password','allow.attorney.firm.merge')){
            $state.go('firms.goldenRecords');
        }else{
            swal({
                title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true
            }, function(inputValue) { 
                if (inputValue === false){
                    return false;
                } 
                if (inputValue === "") {
                    Notification.error("You need to write something!");
                    return false
                }
                else {
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                        .success(function(response) {
                            if (response['result']) {
                                if (response['result']['auth']) {

                                    $rootScope.showLoader(false);
                                    $state.go('firms.goldenRecords');
                                    
                                }else {
                                    $rootScope.showLoader(false);
                                    Notification.error("Error ! Wrong Password");
                                }
                            }else{
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            Notification.error("Oops ! Something went wrong");
                        });

                }
            });  
        } 
    }

    $scope.authMergeFirm = function(){  
                
        if($state.current.name == 'firms.merge_attorney') {
            return false;
        } else if($rootScope.havePermission('allow_access_without_password','allow.attorney.firm.merge')) {
            $state.go('firms.merge_attorney');
        } else {

            swal({
                title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true
            }, function(inputValue) { 
                if (inputValue === false){
                    return false;
                } 
                if (inputValue === "") {
                    Notification.error("You need to write something!");
                    return false
                }
                else {
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                        .success(function(response) {
                            if (response['result']) {
                                if (response['result']['auth']) {

                                    $rootScope.showLoader(false);
                                    $state.go('firms.merge_attorney');
                                    
                                }else {
                                    $rootScope.showLoader(false);
                                    Notification.error("Error ! Wrong Password");
                                }
                            }else{
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            Notification.error("Oops ! Something went wrong");
                        });

                }
            });        
        }
    }

    $scope.authMergeClaimRep = function(){  
        if($state.current.name == 'insurance.merge_claim_rep_name') {
            return false;
        } else if ($rootScope.havePermission('allow_access_without_password','allow.claim.rep.merge')) {
            $state.go('insurance.merge_claim_rep_name');
        } else {
        
            swal({
                title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true
            }, function(inputValue) { 
                if (inputValue === false){
                    return false;
                } 
                if (inputValue === "") {
                    Notification.error("You need to write something!");
                    return false
                }
                else {
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                        .success(function(response) {
                            if (response['result']) {
                                if (response['result']['auth']) {

                                    $rootScope.showLoader(false);
                                    $state.go('insurance.merge_claim_rep_name');
                                    
                                }else {
                                    $rootScope.showLoader(false);
                                    Notification.error("Error ! Wrong Password");
                                }
                            }else{
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            Notification.error("Oops ! Something went wrong");
                        });

                }
            });
        }        
    }

    var newTime;
    // Listen the Batch BroadCast
    $scope.$on('batchCountBroadcastEvent', function (event, data) {
        $rootScope.batchInvoiceArray = data;

        //Save the invoice queue to database
        var invoice_data = {invoice_batch_queue : data};


        clearTimeout(newTime);
        newTime = setTimeout(function(){
            UsersFactory.updateInvoiceQueue(invoice_data).success(function(response){

                $localStorage.batchInvoiceArray = response.data;
                $rootScope.getBatchInvoices();
                // $rootScope.getUserTimeZone();
                $rootScope.showingBatchBouncingCounter = true;
                $timeout(function () {
                    $rootScope.showingBatchBouncingCounter = false;
                }, 500);
    
            }).error(function(error){
                Notification.error(error.message);
            });
        }, 1000);
    });

            // text message BroadCast
    $scope.$on('textmessageCountBroadcastEvent', function (event, data) {
        $rootScope.textMessageUnreadArray = data;                   
        clearTimeout(textTime);
        textTime = setTimeout(function(){
            TextmessagesFactory.textmessagecount().success(function(response){

                $localStorage.textMessageUnreadArray = response.data;                       
                $rootScope.showingTextMessageCounter = true;
                $timeout(function () {
                    $rootScope.showingTextMessageCounter = false;
                }, 500);
    
            }).error(function(error){
                Notification.error(error.message);
            });
        }, 1000);
    });       

    $scope.$on('IdleStart', function() {
        $timeout(function(){
            $scope.isScreenLock = true;
        })
    });

    $scope.$on('IdleEnd', function() {
        console.log("Idle Time");
    });

    $scope.$on('IdleTimeout', function() {
        console.log("Idle timeout");
        $scope.isScreenLock = true;
    });

    $scope.$on('IdleWarn', function(e, countdown) {
        // follows after the IdleStart event, but includes a countdown until the user is considered timed out
        // the countdown arg is the number of seconds remaining until then.
        // you can change the title or display a warning dialog from here.
        // you can let them resume their session by calling Idle.watch()
        Title.setAsIdle(countdown)
    });

    $rootScope.refreshToken = function(){
        $scope.loadingDemo = true;
        loginFactory.refreshToken().success(function(response){
            $scope.loadingDemo = false;
            $cookies.put('token', response.token);
            $cookies.put('Authorization', 'Bearer ' + response.token);
            $scope.isScreenLock = false;
            Title.restore();
        }).error(function(){
            $scope.loadingDemo = false;
            Notification.error("Something went wrong");
            $rootScope.logout();
        });
    };

    $scope.$watch(function () { return $localStorage.batchInvoiceArray; },function(newVal,oldVal){
        $rootScope.batchInvoiceArray = newVal;
    })

    $scope.$watch(function () { return $localStorage.textMessageUnreadArray; },function(newVal,oldVal){
        $rootScope.textMessageUnreadArray = newVal;
    })

    // save the User Permissions
    $rootScope.permissions = {};

    // Check the Permission of User
    $rootScope.havePermission = function (permissionModule, permission,businessunit_id = null) {
        var access = false;
        // $rootScope.permissions_businessunits = {7:$rootScope.permissions}
        // console.log('permissions_businessunits',$rootScope.permissions_businessunits)
        // console.log('permissions_businessunits',$rootScope.permissions)
        
        if(businessunit_id){
            
            access = (Object.keys($rootScope.permissions_businessunits || {}).indexOf(businessunit_id.toString()) !== -1) && $rootScope.permissions_businessunits[businessunit_id.toString()][permissionModule][permission];
            return access;
        }else{
            
            var access = (Object.keys($rootScope.permissions || {}).indexOf(permissionModule) !== -1) && $rootScope.permissions[permissionModule][permission];
            // console.log('return',access);
            return access;
            // var index_roles_businessunits = 0; 
            
            // $rootScope.roles_businessunits.forEach(element => {
            //     if((Object.keys($rootScope.permissions_businessunits || {}).indexOf(element.business_unit_id.toString()) !== -1)){
            //         // var tmp_permissions = $rootScope.permissions_businessunits[element.business_unit_id.toString()].toString() 
            //         var tmp_permissions = $rootScope.permissions
            //         // console.log((Object.keys(tmp_permissions || {}).indexOf(permissionModule) !== -1),tmp_permissions[permissionModule][permission],tmp_permissions,permissionModule)
            //     //    console.log(permissionModule,'ddddddddddd',(($rootScope.permissions_businessunits[element.business_unit_id.toString()][permissionModule],Object.hasOwn($rootScope.permissions_businessunits[element.business_unit_id.toString()],permissionModule),$rootScope.permissions_businessunits[element.business_unit_id.toString()])
            //         if(!access){
            //             access = (Object.keys(tmp_permissions || {}).indexOf(permissionModule) !== -1) && tmp_permissions[permissionModule][permission];
            //             // access = (Object.keys($rootScope.permissions_businessunits || {}).indexOf(element.business_unit_id.toString()) !== -1) && ($rootScope.permissions_businessunits[element.business_unit_id.toString()].toString().indexOf(permissionModule) !== -1) && $rootScope.permissions_businessunits[element.business_unit_id.toString()][permissionModule][permission];
                        
            //         if(index_roles_businessunits+1 >= $rootScope.roles_businessunits.length){
            //             console.log('return',access);
            //             return access;
            //         }
            //         index_roles_businessunits =  index_roles_businessunits + 1;
            //         }
            //     }else{
            //         return access
            //     }
                
            // }); 

        }

    };

    // Check the Role of User
    $rootScope.hasRole = function (role) {
        return (Object.values($rootScope.role || {}).indexOf(role) !== -1);
    };

    // Getting for Current Date, Which will apear in Footer of All Pages.
    $rootScope.currentDate = new Date();

    $rootScope.logout = function (manually_logout) {
        $rootScope.showLoader(true);
        var data = {};
        data.token = $cookies.get("token");

        // Remove the cookies If user press Logout button, Because sometime server have token expire issues
        $cookies.remove("token");
        $cookies.remove("authUser");
        $cookies.remove("audioperson");
        $cookies.remove("dashboardUser");
        $cookies.remove("dashboard_token");
        localStorage.clear();

        $uibModalStack.dismissAll();

        loginFactory.logout(data)
            .success(function (res) {
                Notification.success(res.message ? res.message : 'Logout successfully.');

                $rootScope.showLoader(false);
                if($location.path()!=="/login"){
                    // If user manually press the logout button then we do not need to send back that page where user was. We just redirect to Home
                    if(manually_logout){
                        $state.go("login");
                    }else{
                        $state.go("login", {next: $location.path()});
                    }

                }
            })
            .error(function (err) {
                $rootScope.showLoader(false);
                if($location.path()!=="/login"){
                    // If user manually press the logout button then we do not need to send back that page where user was. We just redirect to Home
                    if(manually_logout){
                        $state.go("login");
                    }else{
                        $state.go("login", {next: $location.path()});
                    }
                }
            });
    };

    $rootScope.showLoader = function (flag) {
        if (flag) {
            $rootScope.loader = true;
        } else {
            $rootScope.loader = false;
        }
    }

    $rootScope.close_window = function () {
        window.close();
    }

    window.onbeforeunload = function (e) {
        e = e || window.event;

        // For IE and Firefox prior to version 4
        if (e) {
            e.returnValue = 'Are you sure want to close this tab?';
        }

        // For Safari
        return 'Are you sure want to close this tab?';
    };

    $rootScope.confirmCheckOperation =function(){
        $http.get(apiUrl + '/api/check/get-current-payroll-check-session').then(function(response) { 
          if(response.data.session.length==0){
            var defaultBuIds=[];
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                defaultBuIds.push(e.business_unit_id)
            });
            swal({
                title: "Are you sure ?",
                text: "<small>You are running Payroll Operations (PO) and will have exclusive control of PO for a 30 minute session to complete the process.<br> Before accepting control, be sure your reports tie-out to the BATCH TOTALS. <br>  Your USER information will be tracked and any OTHER USER attempting to run payroll during your session will see your USER information.<br> Once your update is completed your exclusive session will automatically be released.<small>",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true,
                html:true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $http.post(apiUrl+'/api/check/start-payroll-check-session',{businessUnitIds:defaultBuIds})
                    .success(function(response){
                       if(response.success){
                            Notification.success("Your payroll session start successfully");
                            $state.go("payroll.checkOperations");
                       }
                       else{
                            swal({
                                title:response.user.name +"  is already using the payroll operation. If you need to contact them, please email "+response.user.email,
                                html:true
                            });
                       }
                    });
                }
            });
          }
          else{
            if(response.data.session[0].user_id==$rootScope.auth_user.id){
                $state.go("payroll.checkOperations");
            }
            else{
                swal({
                    title:response.data.session[0].user.name +"  is already using the payroll operation. If you need to contact them, please email "+response.data.session[0].user.email,
                    html:true,
                });
            }
          }
        });
    }
    $rootScope.getReport = function(type, queryString){

        // var str = apiUrl;
        var res = apiUrl;

        var url = "";
        var runInBakground=false; 
        switch (type) {
            case 'accounts_receivable':
                url =  res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=accountsreceivable_rpt&clear_session=1';
                break;
            case 'accounts_receivable_beta':
                var runInBakground=true;
                url =  res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=accountsreceivable_beta_rpt&clear_session=1';
                break;    
            case 'ar_bill_to_beta':
                url =  res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=ar_bill_to_beta&clear_session=1';
                break;    
            case 'ar_summary_standard':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=ar_summary&clear_session=1';
                break;
            case 'attorney_list':
                url =  res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=attorney-list&clear_session=1';
                break;
            case 'sales_attorney_list':
                url =  res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=sales-attorney-list&hide_top_admin_menu=true&hide_top_project_menu=true&clear_session=1&email='+$rootScope.auth_user.email;
                break;
            case 'cash_receipt_detail':
                var runInBakground=true;
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=cash_receipt_detail&clear_session=1';
                break;
            case 'cash_receipt_summary':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=cash_receipt_summary&clear_session=1';
                break;
            case 'check_register':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=check_register&clear_session=1';
                break;
            case 'check_register_detail':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=chk_register_detail&clear_session=1';
                break;
            case 'invoice_profit':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=invoice_profit&clear_session=1';
                break;
            case 'invoice_register_by_state':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=invoice_register_by_state&clear_session=1';
                break;
            case 'invoice_register_by_state_summary':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=invoice_register_by_state_summary&clear_session=1';
                break;
            case 'invoice_register_by_city_summary':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=invoice_register_by_city_summary&clear_session=1';
                    break;
            case 'examinations':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=examinations_by_date&clear_session=1';
                break;
            case 'invoice_register':
                var runInBakground=true;
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=invoice_register&clear_session=1';
                break;
            case 'invoice_register_with_date':
                    url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=invoice_register&clear_session=1'+queryString;
                    break;
            case 'monthly_cash':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=monthy_cash_receipt&clear_session=1';
                break;
            case 'monthly_cash_date':
                    url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=monthy_cash_receipt&clear_session=1'+queryString;
                    break;
            case 'work_in_progress':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=work_in_progress&clear_session=1';
                break;
            case 'work_in_progress_by_attorney':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=work_in_progress_by_attorney&clear_session=1';
                // added fti
                break;
            case 'view_reporter_pay_by_pay_date':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=view_reporter_pay_by_pay_date&clear_session=1';
                // added fti
                break;
            case 'view_typist_pay_by_pay_date':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=view_typist_pay_by_pay_date&clear_session=1';
                // added fti
                break;
            case 'work_in_progress_by_reporter':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=work_in_progress_by_reporter&clear_session=1';
                break;

            case 'work_in_progress_by_attorney_code':
                url = res + '/reportico/run.php?execute_mode=EXECUTE&project=Deitz Back Office&xmlin=work_in_progress_by_attorney&MANUAL_criteriaattorney='+ queryString;
                break;
            case 'invoice_by_job_detail':
                url = res + '/reportico/run.php?execute_mode=EXECUTE&project=Deitz Back Office&xmlin=invoice_by_job_detail$clear_session=1';
                break;
            case 'reporters_and_typists_for_a_date_range':
                url = res + '/reportico/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=reporters_and_typists_for_a_date_range&clear_session=1';
                break;
        }

        // Added user timezone as param
        url = url + '&timezone='+$rootScope.user_timezone;

        var reportModal = $uibModal.open({
            templateUrl: "modules/reports/report_view.html",
            controller: function($scope,givenUrl,$uibModalInstance){
                // givenUrl.replace("/reportico/", "/")
                $scope.givenUrl = givenUrl;
                $scope.runInBakground=runInBakground;
                // $scope.givenUrl = $sce.trustAsResourceUrl(givenUrl);
                $scope.spinnerLoader= true;
                $scope.closePopupWindow = function(){
                    $uibModalInstance.close(true);
                };
                $scope.iframeLoadedCallBack = function(){
                    // do stuff
                    $scope.spinnerLoader= false;
                    $scope.$apply();
                    console.log("loading Complete");
                    
                    if($scope.runInBakground){
                        // add extra peramaters
                        report_xml=new URLSearchParams(url).get('xmlin');
                        $("#reportIframe").contents().find('form').append('<input type="hidden" name="xmlin" value="'+report_xml+'" />')
                        $("#reportIframe").contents().find('form').append('<input type="hidden" name="user_email" value="'+$scope.auth_user.email+'" />')
                        $("#reportIframe").contents().find('form').append('<input type="hidden" name="report" value="'+type+'" />')

                        // change reportico ajax url to our api one
                        document.getElementById("reportIframe").contentWindow.reportico_ajax_script=apiUrl+'/api/add-reportico-payload';
                        
                        // do some adjustment for adminmenu add projectmenu button due to change in reportico ajax url
                        $("#reportIframe").contents().find('.swAdminButton2').click(function(){
                            document.getElementById("reportIframe").contentWindow.reportico_ajax_script=apiUrl+'/reportico/run.php';
                        });
                    }
                }

                $scope.printDiv = function(divName) {
                    // var printContents = document.getElementById('abc').innerHTML;
                    // var popupWin = window.open('', '', '');
                    //
                    // popupWin.document.open();
                    // popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
                    // popupWin.document.close();

                    setTimeout(function () {
                        $("#reportIframe").get(0).contentWindow.print();
                    }, 1000);
                }
            },
            resolve: {
                givenUrl: function () {
                    console.log(url);
                    return url;
                }
            },
            windowClass: 'full-width-model',
            keyboard: false,
            backdrop: false,
            size: 'lg',
            close: function () {

            }
        });


        reportModal.result.then(function () {
        }, function () {
            console.log("Modal Dismissed!!!");
        });
    };

    $rootScope.getReport_new = function(type, queryString){
        

        // var str = apiUrl;
        var res = apiUrl;

        var url = "";
        switch (type) {
            case 'accounts_receivable':
                url =  res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=accountsreceivable_rpt&clear_session=1';
                break;
                case 'accounts_receivable_beta':
                url =  res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=accountsreceivable_beta_rpt&clear_session=1';
                break;
            case 'ar_summary_standard':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=ar_summary&clear_session=1';
                break;
            case 'attorney_list':
                url =  res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=attorney-list&clear_session=1';
                break;
            case 'cash_receipt_detail':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=cash_receipt_detail&clear_session=1';
                break;
            case 'cash_receipt_summary':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=cash_receipt_summary&clear_session=1';
                break;
            case 'check_register':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=check_register&clear_session=1';
                break;
            case 'check_register_detail':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=chk_register_detail&clear_session=1';
                break;
            case 'invoice_profit':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=invoice_profit&clear_session=1';
                break;
            case 'examinations':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=examinations_by_date&clear_session=1';
                break;
            case 'invoice_register':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=invoice_register&clear_session=1';
                break;
            case 'monthly_cash':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=monthy_cash_receipt&clear_session=1';
                break;
            case 'work_in_progress':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=work_in_progress&clear_session=1';
                break;
            case 'work_in_progress_by_attorney':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=work_in_progress_by_attorney&clear_session=1';
                // added fti
                break;
            case 'view_reporter_pay_by_pay_date':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=view_reporter_pay_by_pay_date&clear_session=1';
                // added fti
                break;
            case 'view_typist_pay_by_pay_date':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=view_typist_pay_by_pay_date&clear_session=1';
                // added fti
                break;
            case 'work_in_progress_by_reporter':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=work_in_progress_by_reporter&clear_session=1';
                break;

            case 'work_in_progress_by_attorney_code':
                url = res + '/reportico_new/run.php?execute_mode=EXECUTE&project=Deitz Back Office&xmlin=work_in_progress_by_attorney&MANUAL_criteriaattorney='+ queryString;
                break;
            case 'invoice_by_job_detail':
                url = res + '/reportico_new/run.php?execute_mode=EXECUTE&project=Deitz Back Office&xmlin=invoice_by_job_detail$clear_session=1';
                break;
            case 'reporters_and_typists_for_a_date_range':
                url = res + '/reportico_new/run.php?execute_mode=PREPARE&project=Deitz Back Office&xmlin=reporters_and_typists_for_a_date_range&clear_session=1';
                break;
        }

        var reportModal = $uibModal.open({
            templateUrl: "modules/reports/report_view.html",
            controller: function($scope,givenUrl,$uibModalInstance){
                $scope.givenUrl = givenUrl;
                $scope.spinnerLoader= true;
                $scope.closePopupWindow = function(){
                    $uibModalInstance.close(true);
                };
                $scope.iframeLoadedCallBack = function(){
                    // do stuff
                    $scope.spinnerLoader= false;
                    $scope.$apply();
                    console.log("loading Complete");
                }

                $scope.printDiv = function(divName) {
                    // var printContents = document.getElementById('abc').innerHTML;
                    // var popupWin = window.open('', '', '');
                    //
                    // popupWin.document.open();
                    // popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
                    // popupWin.document.close();

                    setTimeout(function () {
                        $("#reportIframe").get(0).contentWindow.print();
                    }, 1000);
                }
            },
            resolve: {
                givenUrl: function () {
                    console.log(url);
                    return url;
                }
            },
            windowClass: 'full-width-model',
            keyboard: false,
            backdrop: false,
            size: 'lg',
            close: function () {

            }
        });


        reportModal.result.then(function () {
        }, function () {
            console.log("Modal Dismissed!!!");
        });
    };

    $rootScope.changeFavIcon = function(){
        var state = ($rootScope.state.$current.parent.name).split('.');
        var moduleName = state[0];
        var favIconRoute = ['diary', 'attorney', 'insurance', 'representative', 'payroll', 'invoice', 'firms'];

        if(favIconRoute.indexOf(moduleName) < 0){
            icon = "favicon.ico";
        } else{
            icon = 'favicons/'+ moduleName+'.png';
        }
 

        (function() {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/png';
            link.rel = 'icon';
            link.href = icon;

            document.getElementsByTagName('head')[0].appendChild(link);
        })();
    };

    $rootScope.deletePayroll = function(job_resource_id, forReporter, forTypist, cb){
        swal({
                title: "Are you sure ?",
                text: "You are about to delete payroll for reporter/typist.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    payrollFactory.post('/api/deletePayroll', {job_resource_id : job_resource_id, forReporter: forReporter, forTypist: forTypist})
                        .success(function(response) {
                            $rootScope.showLoader(false);

                            if(response.error){
                                SweetAlert.swal("Oops !", response.result.message ||  "Something went wrong!", "error");
                            } else{
                                Notification.success('Payment has been deleted successfully.');
                            }
                            cb();

                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            SweetAlert.swal("Oops !", "Something went wrong!", "error");
                            cb();
                        });
                }
            });
    };

    $rootScope.open_A_R_Fax_window = function(ATT_KEY){
        var A_R_FaxModal = $uibModal.open({
            templateUrl: "modules/firms/a_r_fax.html",
            controller: function($scope, $uibModalInstance, $sce, $rootScope){
                $scope.trustSrc = function(src) {
                    return $sce.trustAsResourceUrl(src);
                };
                var auth_user = $rootScope.auth_user;
                var enscript = encodeURIComponent("ar_fax.dtop?r_tablename=ar&r_searchfield=ATTORNEY&r_search="+ATT_KEY+"&searchbutton=Go");

                $scope.givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

                $scope.spinnerLoader= true;
                $scope.closePopupWindow = function(){
                    $uibModalInstance.close(true);
                };
                $scope.iframeLoadedCallBack = function(){
                    // do stuff
                    $scope.spinnerLoader= false;
                    $scope.$apply();
                    console.log("loading Complete");
                };
            },
            resolve: {
            },
            windowClass: 'full-width-model',
            keyboard: false,
            backdrop: false,
            size: 'lg',
            close: function () {

            }
        });


        A_R_FaxModal.result.then(function () {
        }, function () {
            console.log("Modal Dismissed!!!");
        });
    };

    $scope.statements_for_all_clients = function(){
        var reportModal = $uibModal.open({
            templateUrl: "modules/firms/browse/statement_modal.html",
            controller: function($scope, $uibModalInstance,$sce,$rootScope){

                var auth_user = $rootScope.auth_user;
                $scope.givenUrl = $sce.trustAsResourceUrl( $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script=batch_statement.php");

                $scope.spinnerLoader= true;
                $scope.closePopupWindow = function(){
                    $uibModalInstance.close(true);
                };
                $scope.iframeLoadedCallBack = function(){
                    // do stuff
                    $scope.spinnerLoader= false;
                    $scope.$apply();
                    console.log("loading Complete");
                }
            },
            resolve: {
            },
            windowClass: 'full-width-model',
            keyboard: false,
            backdrop: false,
            size: 'lg',
            close: function () {
            }
        });

        reportModal.result.then(function () {
        }, function () {
            console.log("Modal Dismissed!!!");
        });
    };

    $scope.currentModal = '';
    window.closeModal = function(){
        $scope.currentModal.close();
    };

    $rootScope.launchIFrame = function(givenUrl, cb){
        var launchUserModal = $uibModal.open({
            templateUrl: "modules/resources/templates/iFrame.html",
            controller: function($scope, $uibModalInstance, $sce, $rootScope){
                $scope.trustSrc = function(src) {
                    return $sce.trustAsResourceUrl(src);
                };

                $scope.givenUrl = givenUrl;

                $scope.spinnerLoader= true;
                $scope.closePopupWindow = function(){
                    $uibModalInstance.close(true);
                };
                $scope.iframeLoadedCallBack = function(){
                    // do stuff
                    $scope.spinnerLoader= false;
                    $scope.$apply();
                    console.log("loading Complete");
                };
            },
            resolve: {
            },
            windowClass: 'full-width-model',
            keyboard: false,
            backdrop: false,
            size: 'lg',
            close: function () {
                cb();
            }
        });

        $scope.currentModal = launchUserModal;

        launchUserModal.result.then(function () {
            cb();
        }, function () {
            console.log("Modal Dismissed!!!");
            cb();
        });
    };

    $rootScope.redirectknowledgebase = function(){
        $http.get(apiUrl + '/api/get-knowledgebase-guide-data').then(function(response) { 
            $rootScope.showLoader(false);
            if(response.data.status){
                var givenUrl = response.data.result;                
                $window.open(givenUrl, '_blank');
            }else{
                Notification.error('File Not Exist. Please Upload Knowledge Base Guide PDF'); 
            }
        });
    } 
    $rootScope.redirectDepoLaunchMenu = function(){
        $rootScope.showLoader(true);
            var req_data = {};
            req_data.email = $rootScope.auth_user.email;
            // getting depo user api
            $http.post(apiUrl + '/api/users/depositionnet-user', req_data)
            .success(function(depo_user) { 
                uid =depo_user.user.uid
                $scope.depo_user = depo_user.user;    
                if($scope.depo_user.reporter_id){
                    var enscript = 'launch_user.dtop?uid='+uid+'&actionfrom=backend&toggle_to_repoter=1';
                }
                else{
                    var enscript = 'launch_user.dtop?uid='+uid+'&actionfrom=backend';    
                }
                var auth_user = $rootScope.auth_user;
                
                $http.post(apiUrl + '/api/auth-user-get-token', {uid : uid}).then(function(response) { 
                    $rootScope.showLoader(false);
                    if(response.data.status){
                        var givenUrl = $rootScope.depoHost +"/"+enscript+"&access_token="+response.data.token;
                        $window.open(givenUrl, '_blank');
                    }else{
                        Notification.error('Something went wrong!'); 
                    }
                });
            
            });
      
    };
    $rootScope.redirectDepo = function(depoUrl){
        if(depoUrl === 'admin_pendingjobs_new.dtop'){
            depoUrl = "admin_pendingjobs_new.dtop?r_searchfield=DATE_TAKEN&r_search=" + moment().add(1, 'days').format('YYYY-MM-DD');
        }
        var enscript = encodeURIComponent(depoUrl);

        var auth_user = $rootScope.auth_user;
        var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

        $window.open(givenUrl, '_blank');
    };

    $scope.open_STATS_report = function(attorneyFirmId){
        $rootScope.showLoader(true);
        var STATS_reportModal = $uibModal.open({
            templateUrl: "modules/firms/stats_report_modal.html",
            controller: function($scope, $uibModalInstance, $sce, $rootScope,stats_report,cashReceiptFactory){
                $rootScope.showLoader(false);
                $scope.stats_report = stats_report || [];
                $scope.paymentTypes={};
                angular.forEach(cashReceiptFactory.paymentTypes, function(value, key) {
                    $scope.paymentTypes[value.key]=value.value;
                 });

                $scope.closePopupWindow = function(){
                    $uibModalInstance.close(true);
                };

            },
            resolve: {
                stats_report : ['commonFactory', function (commonFactory) {
                    return commonFactory.post('/api/firms/check-stats-report/'+attorneyFirmId).then(function (response) {
                        return response.data.result.stats;
                    });
                }]
            },
            windowClass: 'full-width-model',
            keyboard: false,
            backdrop: false,
            size: 'lg',
            close: function () {

            }
        });
    };

    $rootScope.emailOrFaxStatementsToClaimRepOrAttorney = function(data, cb){

        function sendStatements(params){
            swal({
                title: "Are you sure ?",
                text: "You are about to send the statements.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Send it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    var insCompanies = [];
                    var ids = [];
                    if(data.statementsTo =='attorneyFirm'){
                        angular.forEach(data.selectedAttorneys, function(value, key) {
                           ids[key] = value.id;
                        });
                    }else if(data.statementsTo =='individual'){
                        angular.forEach(data.selectedIndividuals, function(value, key) {
                            ids[key] = value.id;
                         });
                    }
                    else{
                        angular.forEach(data.selectedClaimRep, function(value, key) {
                           ids[key] = value.ClaimRepID;
                        });

                        angular.forEach(data.selectedInsCompany, function(value, key) {
                            insCompanies[key] = value.CR_CompanyName;
                        });
                    }

                    commonFactory.post('/api/sendStatements', {
                        ids : ids,
                        via: data.via,
                        inputValue:data.inputValue,
                        business_unit_id:data.business_unit_id,
                        ccList:data.ccList,
                        type : data.statementsTo,
                        insuranceCompanies: insCompanies,
                        includeInvoices : data.includeInvoices ? data.includeInvoices : false,
                        needToUpdateProfile : data.needToUpdateProfile ? data.needToUpdateProfile : false,
                        additionalComment : data.selectedMessage,
                        excludeBilledToIds : data.excludeBilledToIds,
                        excludeTPA : data.excludeTPA,
                        includeTPA : data.includeTPA,
                        selectedBusinessUnitIDs : data.selectedBusinessUnitIDs ?  data.selectedBusinessUnitIDs : [],
                        includeExcludeBilledToIds : data.includeExcludeBilledToIds,
                        filterDate : data.filterDate,
                        ordering_attname_id : data.ordering_attname_id,
                        startDate : moment(data.startDate).format('YYYY-MM-DD'),
                        endDate : moment(data.endDate).format('YYYY-MM-DD') 
                    })
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.message || "Something went wrong!");
                            cb(null, true);
                        } else{
                            Notification.success(response.message || "Statements has been sent.");
                            cb(true, null);
                        }

                    })
                    .error(function(err) {
                        Notification.error("Something went wrong!");
                        $rootScope.showLoader(false);
                        cb(true, null);
                    });
                }
            });
        };

        var selectedData = [];
        var fax = '';
        var email = '';
        var name = '';

        /* Checking the statements is needs to be send to attorney or claim rep.*/
        if(data.statementsTo === 'attorneyFirm'){
            selectedData = data.selectedAttorneys;
            fax = selectedData[0].FAX;
            email = selectedData[0].EmailAddress;
            name = selectedData[0].NAME
        } else if(data.statementsTo === 'individual'){
            selectedData = data.selectedIndividuals;
            email = selectedData[0].Email_Address;
            name = selectedData[0].NAME
        }
        else{
            selectedData = data.selectedClaimRep;
            fax = selectedData[0].CR_Fax;
            email = selectedData[0].CR_EmailAddress;
            name = selectedData[0].ClaimRepFirstName + " " + selectedData[0].ClaimRepLastName
        }        
        

        var sendStatementsModal = $uibModal.open({
            templateUrl: "modules/resources/templates/send_statements_modal.html",
            controller: function($scope, $uibModalInstance, $sce, $rootScope, $http, apiUrl){
                $scope.formData = {};
                $scope.orderingFirmAttorneys = {};
                $scope.formData = data;
                $scope.Authuser = JSON.parse($cookies.get('authUser'));
                $scope.formData.ccList = $scope.Authuser.email;
                $scope.formData.apEmail = email != '' ? true: false;
                $scope.formData.eachSelf = false;
                $scope.formData.ordering_attname_id = [];

                $scope.business_unit_id = null;
                $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                    $scope.BusinessUnitsList = response.data.result.data;  
                });
                
                $scope.buChange = function(){
                    $http.post(apiUrl+'/api/get-fax-email-messages', {'selectedBusinessUnitIDs' : $scope.formData.business_unit_id}).success(function(response){
                        $scope.formData.messages = response.data; 
                    });
                }

                $scope.allowOverWrite = false;
                $scope.title =  data.via.toUpperCase() + " STATEMENTS ";
                $scope.via =  data.via == 'email' ? "E-MAIL " : "FAX #";
                $scope.attOrClmrp =  data.statementsTo === 'attorneyFirm' ? 'ATTORNEY FIRM' : "CLAIM REP ";

                /*Check if the only one selection for the claim rep / attorney then we are allowing to overwrite the email/ fax number.*/
                if(selectedData.length === 1){
                    $scope.allowOverWrite = true;
                    $scope.formData.inputValue = data.via === 'fax' ? fax : email;                    
                }
                $scope.selectMessage = function(messages){
                    $scope.formData.selectedMessage = messages;
                }
                $scope.send = function(isValid){
                    if(isValid)
                        $uibModalInstance.close({isSend : true, formData : $scope.formData});  
                };
                $scope.apCheckboxChanged = function(){
                    if ($scope.formData.apEmail){
                        $scope.formData.inputValue = email;
                    }else{
                        $scope.formData.inputValue = '';
                    }
                };
                $scope.eachSelfChanged = function(){
                    if(selectedData.length === 1){
                        if($scope.formData.eachSelf){
                            $rootScope.showLoader(true);
                            let id = selectedData[0].id;
                            commonFactory.post('/api/attorneybyfirmWithInvoiceCount', {firm_id:id})
                                .success(function (response) {
                                    if (response) {
                                        if (response && response.result && response.result.attorney_data) {
                                            $scope.orderingFirmAttorneys = response.result.attorney_data;
                                            angular.forEach($scope.orderingFirmAttorneys, function(value, key) {
                                                $scope.formData.ordering_attname_id.push(value.id);
                                            });
                                        }
                                    }
                                    $rootScope.showLoader(false);
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                });
                        }else{
                            $scope.orderingFirmAttorneys = {};
                            $scope.formData.ordering_attname_id = [];
                        }

                    }

                };

                $scope.closePopupWindow = function(a){
                    $uibModalInstance.close({isSend : false, formData : {}});
                };
            },
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                }
            },

            keyboard: false,
            backdrop: false,
            close: function () {

            }
        });

        sendStatementsModal.result.then(function (data) {
            if(data.isSend){
                sendStatements(data.formData);
            }
        }, function () {
            
        });
    };

    $rootScope.refundCredit = function(data, mainCallBack){
        var sendStatementsModal = $uibModal.open({
            templateUrl: "modules/resources/templates/credit_refund.html",
            controller: function($scope, $uibModalInstance){
                $scope.title = "Refund on account credit"
                $scope.selectedCreditIds = [];

                //Getting Attorneys list
                $scope.attorneysByName = function(val) {
                    return $http.get(apiUrl + '/api/attorneySearchByName', {
                        params: {
                            name: val,
                            from_cash_receipt :false
                        }
                    }).then(function(response) {
                        return response.data.result.map(function(item) {
                            return item;
                        });
                    });
                };

                $scope.onSelectSource = function(item, model, label){
                    $scope.formData.source = item;
                    data.source_id = item.id;
                    $scope.assignedSource = true;
                    $scope.fetchData();
                };

                $scope.unSelectSource = function(){
                    if($scope.assignedSource){
                        $scope.title = "Refund on account credit";
                        $scope.assignedSource = false;
                        $scope.formData.invoices = [];
                        $scope.formData.refundable_amount = 0;
                    }
                };

                $scope.calculateAmount = function(selectedInvoices){
                    $scope.formData.refundable_amount = 0;
                    $scope.selectedCreditIds = [];
                    angular.forEach(selectedInvoices, function(value, key) {
                        $scope.selectedCreditIds.push(value.id);
                        $scope.formData.refundable_amount += value.available_amt;
                    });
                };

                $scope.fetchData = function(){

                    if(!data.source_id){
                        return;
                    }

                    $rootScope.showLoader(true);
                    $scope.formData = {
                        refundable_amount : 0,
                        source_id: data.source_id, 
                        source_type : data.source_type
                    };
                    async.parallel([
                        function(cb){
                            commonFactory.get('/api/attorneys/'+ $scope.formData.source_id).success(function(response) {                        
                                if(!response.error){
                                    $scope.title = "Refund credit to "+ response.result.data.NAME;
                                    $scope.assignedSource = true;
                                    $scope.formData.source = response.result.data;
                                    cb(null, true);
                                    return
                                }
                                cb(true, null);
                                return;
                            })
                            .error(function(err) {
                                cb(true, null);
                                return;
                            });
                        },
                        function(cb){
                            commonFactory.post('/api/unused_credit_list', {
                                    resType:1,
                                    source_id:$scope.formData.source_id
                                }).success(function(response) {                        
                                    if(!response.error){
                                        $scope.formData.invoices = response.result.credits;
                                        cb(null, true);
                                        return
                                    }
                                    cb(true, null);
                                    return;
                                })
                                .error(function(err) {
                                    cb(true, null);
                                    return;
                                });
                        }
                    ], function(err, resp) {
                        if(err){
                            Notification.error("Something went wrong!");
                        }
                        $rootScope.showLoader(false);
                    });
                };

                $scope.fetchData();

                $scope.submit = function(isValid){
                    if(isValid){
                        swal({
                            title: "Are you sure ?",
                            text: "You are about to refund the credit.",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes, Do it",
                            cancelButtonText: "No!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {                                
                                var params = {
                                    source_id: $scope.formData.source_id,
                                    source_type: $scope.formData.source_type,
                                    selectedCreditIds: $scope.selectedCreditIds,
                                    check_number : $scope.formData.check_number
                                };

                                $rootScope.showLoader(true);
                                commonFactory.post('/api/refundOnCreditAmount', params)
                                .success(function(response) {
                                    $rootScope.showLoader(false);
                                    if(response.error){
                                        Notification.error(response.message || "Something went wrong!");
                                    } else{
                                        Notification.success(response.message || "Amount successfully refunded.");
                                        $uibModalInstance.close({isDone : true, formData : {}});
                                    }            
                                })
                                .error(function(err) {
                                    Notification.error("Something went wrong!");
                                    $rootScope.showLoader(false);
                                });
                            }
                        });
                    }
                }

                $scope.closePopupWindow = function(a){
                    $uibModalInstance.close({isDone : false, formData : {}});
                };
            },
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                }
            },
            keyboard: false,
            backdrop: false,
            close: function () {

            }
        });

        sendStatementsModal.result.then(function (data) {
            if(data.isDone){
                if(mainCallBack){
                    mainCallBack(null, true);
                }                
            }
        }, function () {
            
        });
    };

    $rootScope.cloneJob = function(JOB_NO,resourceCount,serviceCount,otherData){
        var sendStatementsModal = $uibModal.open({
            templateUrl: "modules/diary/clone/clone_job.html",
            controller: function($scope, $uibModalInstance, diaryFactory){

                $scope.validations = diaryFactory.validations;
                $scope.messages = diaryFactory.messages;

                $scope.title = "Enter new Calendar details";
                $scope.diaryData = {
                    DATE_TAKEN : moment()
                };
                $scope.clonJobStatus = 'schedule';
                $scope.clonJobResourcesAndServices = false;
                $scope.clonJobServices = false;
                
                $scope.jobStausCheckPointForCloneJob= function(isValid){
                    if(isValid){
                        let jobdate = moment($scope.diaryData.DATE_TAKEN).format('YYYY-MM-DD');
                        let dayIncrement = 1;
            
                        if(moment().day() == 5){
                            dayIncrement = 3;
                        }else if(moment().day() == 6){
                            dayIncrement = 2;
                        }
            
                        let todaydate = moment().format('YYYY-MM-DD');
                        let nextBusinessDay  = moment().add(dayIncrement, 'days').format('YYYY-MM-DD');
                        if(jobdate == nextBusinessDay || jobdate == todaydate){
                            swal({
                                title: "PLEASE SELECT THE STATUS FOR THE JOB.",
                                text: '<button  id="btnScheduledCloneJob" onclick="checkStatus()" >Scheduled</a> ' +
                                '<button id="btnPendingCloneJob" >Pending Confirmation</a> ' +
                                '<button  id="btnConfirmedCloneJob" >Confirmed</a>',
                                html: true,
                                showConfirmButton: false
                            })
                        }
                        else{
                            $scope.checkJobResources();
                        }
                    }
                }

                $scope.checkJobResources =function (){
                        if(resourceCount!=0 || serviceCount !=0){
            
                            if(resourceCount!=0){
                                swalExtend({
                                    swalFunction: function () {
                                        swal({
                                                title: "Are you sure ?",
                                                text: "Would you like to keep the resource(s) and/or service(s)  assigned to the job?",
                                                showCancelButton: true,
                                                confirmButtonColor: "#09375f",
                                                confirmButtonText: "Clone Services only",
                                                closeOnConfirm: true,
                                                cancelButtonText: "Cancel this process - Don’t clone",
                                                showCancelButton: true,
                                                closeOnCancel: true
                                            },
                                            function (isConfirm) {
                                                if (isConfirm) {
                                                    $scope.clonJobServices = true;
                                                    $scope.clonJobResourcesAndServices = false;
                                                    setTimeout(() => {
                                                        $scope.checkZoomLink();
                                                    }, 300);
                                                }
                                            
                                            });
                                    },
                                    hasCancelButton: true,
                                    buttonNum: 2,
                                    hasCallback: false,
                                    buttonColor: ["#09375f","#09375f"],
                                    buttonNames: ["Clone with Services and Assigned Resources","Clone without services and without resources"],
                                    clickFunctionList: [
                                        function () {
                                            $scope.clonJobResourcesAndServices = true;
                                            $scope.clonJobServices = false;
                                            setTimeout(() => {
                                                $scope.checkZoomLink();
                                            }, 300);
                                        },
                                        function () {
                                            $scope.clonJobResourcesAndServices = false;
                                            $scope.clonJobServices = false;

                                            setTimeout(() => {
                                                $scope.checkZoomLink();
                                            }, 300);
                                        }
                                        
                                    ]
                                });
                            }
                            else if(serviceCount !=0){
                                swal({
                                    title: "Are you sure ?",
                                    text: "Would you like to keep the service(s) assigned to the job?",
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#09375f",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },function(isConfirm){
                                    if(isConfirm){
                                        $scope.clonJobServices = true;
                                    }
                                    setTimeout(() => {
                                        $scope.checkZoomLink();
                                }, 300);
                                })
                                }
                            
                        }else{
                            $scope.checkZoomLink();
                        }
                    
                }

                $scope.checkZoomLink= function(){
                    $scope.clonZoom = false;
                    if(otherData.vc_link && otherData.zoom_meeting_id && otherData.zoom_meeting_password){
                        swal({
                            title: "Are you sure ?",
                            text: "Would you like to keep the zoom link id/pwd?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },function(isConfirm){
                            if(isConfirm){
                                $scope.clonZoom = true;
                            }
                            setTimeout(() => {
                                $scope.submit(true);
                        }, 300);
                        })
                    }
                    else{
                        $scope.submit(true);
                    }
                }

                $(document).on('click', "#btnScheduledCloneJob", function() {
                    $scope.clonJobStatus = 'schedule';
                    setTimeout(() => {
                            $scope.checkJobResources();
                    }, 300);
                });
                $(document).on('click', "#btnPendingCloneJob", function() {
                    $scope.clonJobStatus = 'pending';
                    setTimeout(() => {
                         $scope.checkJobResources();
                    }, 300);
                });

                $(document).on('click', "#btnConfirmedCloneJob", function() {
                    $scope.clonJobStatus = 'confirmed';
                    setTimeout(() => {
                         $scope.checkJobResources();
                    }, 300);
                });
                          

                $scope.submit = function(isValid){
                    if(isValid){
                        swal({
                            title: "Are you sure ?",
                            text: "You are about to clone a JOB.",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes, Do it",
                            cancelButtonText: "No!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                var params = {
                                    JOB_NO : JOB_NO,
                                    DATE_TAKEN : moment($scope.diaryData.DATE_TAKEN).format('MM/DD/YYYY'),
                                    dte_taken : moment($scope.diaryData.DATE_TAKEN).format('YYYY-MM-DD'),
                                    TIME_TAKEN : moment($scope.diaryData.TIME_TAKEN).format('hh:mm A'),
                                    jobStatus : $scope.clonJobStatus,
                                    clonJobResourcesAndServices : $scope.clonJobResourcesAndServices,
                                    clonJobServices: $scope.clonJobServices,
                                    clonZoom : $scope.clonZoom
                                };

                                $rootScope.showLoader(true);
                                commonFactory.post('/api/cloneJob', params)
                                .success(function(response) {
                                    if(response.error){
                                        Notification.error(response.result.message || "Something went wrong!");
                                        $rootScope.showLoader(false);
                                    } else{
                                        Notification.success({ message : "<b> NEW JOB NO: "+ response.result.newJob.JOB_NO +"</b>", delay : 10000,  title: 'Job has been cloned '});
                                        $uibModalInstance.close({isDone : true, formData : {}});
                                        $state.go('diary.editcase.step_one', {id : response.result.newJob.JOB_NO}, {notify: true});
                                        if(response.result.newJob.resourceOffConflict){
                                            swal({
                                                title: "Alert",
                                                text: response.result.newJob.resourceConflictMesssage,
                                                type: "warning",
                                            });
                                        }
                                    }            
                                })
                                .error(function(err) {
                                    Notification.error("Something went wrong!");
                                    $rootScope.showLoader(false);
                                });
                            }
                        });
                    }
                }

                $scope.closePopupWindow = function(a){
                    $uibModalInstance.close({isDone : false, formData : {}});
                };
            },

            keyboard: false,
            backdrop: false,
            close: function () {

            }
        });

        sendStatementsModal.result.then(function (data) {
            if(data.isDone){
                
            }
        }, function () {
            
        });
    }

    /* This function is use for autologin in destbackoffic reporing */
    $rootScope.autoLogin = function(firm_code){        
        var auth_user = $rootScope.auth_user;   
        $scope.givenUrl = $sce.trustAsResourceUrl( $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script=mystatement.php?firm_code="+encodeURIComponent(firm_code));  
        window.open($scope.givenUrl,'_blank');
    }

    $rootScope.sendInvoice = function(invIds, via){
        
        if(typeof invIds == 'object'){
            var ids = [];
            angular.forEach(invIds,function(value, key){
                ids[key] = value.text;
            });
        }else{
            ids = [invIds];
        }

        $rootScope.showLoader(true);
        var modalInstance = $uibModal.open({
            templateUrl : 'modules/resources/templates/send_invoice_popup.html',
            controller : function($scope, $uibModalInstance, $http, apiUrl, ids, via, Notification){
               
                $scope.formData = {
                    ids : ids,
                    via : via
                };
                $scope.formData.invoice_send_to = 'firm';
                $scope.invoice_data = [];
                $scope.via = via;
                $scope.sent_invoice_message_log = {};
                $scope.selectedClientCount = 0;

                $scope.closeModal =  function(){
                    $uibModalInstance.close();
                }

                $scope.getEmailOrFax = function(type){
                    var data = {
                        type : type,
                        ids : ids 
                    };
                    $http.post(apiUrl + '/api/invoice/get-email-or-fax', data).success(function(res){
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{    
                            $scope.invoice_data = res.result.data;
                            $scope.selectedClientCount = res.result.count;
                            $scope.formData.send_on = '';
                            var email_fax = {
                                firm : {},
                                claimRep : {},
                                insurance : {}
                            };
                            angular.forEach($scope.invoice_data,function(value, key){
                                if(via == 'fax'){
                                    
                                    if($scope.formData.invoice_send_to == 'firm'){

                                        email_fax['firm'][value.attorney_firms.id] = value.attorney_firms.FAX ? value.attorney_firms.FAX : '';
                                    
                                    }else if($scope.formData.invoice_send_to == 'claimRep'){

                                        if(value.claim_representative){
                                            email_fax['claimRep'][value.claim_representative.ClaimRepID] = value.claim_representative.CR_Fax ? value.claim_representative.CR_Fax : '';
                                        }
                                    
                                    }else{

                                        if(value.claim_representative && value.claim_representative.insurance_comp){ 
                                            email_fax['insurance'][value.claim_representative.insurance_comp.id] = value.claim_representative.insurance_comp ? value.claim_representative.insurance_comp.MainFaxNumber : '';
                                        }
                                    
                                    }

                                }else{
                                    if($scope.formData.invoice_send_to == 'firm'){
                                        
                                        email_fax['firm'][value.attorney_firms.id] = value.attorney_firms.EmailAddress ? value.attorney_firms.EmailAddress : '';
                                        //attache attorney
                                        var email
                                        if(value.attorney_name && value.attorney_name.auto_attach_invoice == 'Y' && value.attorney_name.Email_Address){
                                            email = value.attorney_name.Email_Address
                                        }
                                        if(value.attorney_name && value.attorney_name.invoice_designee_emails){
                                            if(email){
                                                email = email+','+  value.attorney_name.invoice_designee_emails 
                                            }else{
                                                email =  value.attorney_name.invoice_designee_emails 
                                            }
                                        }
                                        if(email_fax['firm'][value.attorney_firms.id] && email){
                                            email_fax['firm'][value.attorney_firms.id] = email_fax['firm'][value.attorney_firms.id]+','+  email 
                                        }else if(!email_fax['firm'][value.attorney_firms.id]){
                                            email_fax['firm'][value.attorney_firms.id] = email 
                                        }
                                    
                                    }else if($scope.formData.invoice_send_to == 'claimRep'){
                                        
                                        if(value.claim_representative){
                                            email_fax['claimRep'][value.claim_representative.ClaimRepID] = value.claim_representative.CR_EmailAddress ? value.claim_representative.CR_EmailAddress : '';
                                        }

                                    }else{

                                        if(value.claim_representative && value.claim_representative.insurance_comp){
                                            email_fax['insurance'][value.claim_representative.insurance_comp.id] = value.claim_representative.insurance_comp.MainEmailAddress ? value.claim_representative.insurance_comp.MainEmailAddress : '';
                                        }

                                    }
                                }
                                
                            });
                            angular.forEach(email_fax[$scope.formData.invoice_send_to],function(value, key){
                                $scope.formData.send_on += value ? value +',' : ''; 
                            });
                        }   
                    }).error(function(err){
                        Notification.error(err);
                    });
                }

                $scope.getEmailOrFax($scope.formData.invoice_send_to);    
                
                $scope.send = function(isValid){
                    var msg = '';
                    if($scope.selectedClientCount > 1){
                        if($scope.formData.invoice_send_to == 'firm'){
                            msg = '<font color="red">You have selected invoices of multiple firms</font>';
                            isValid = false;
                            swal({
                                title: "Error!",
                                type: "error",
                                text: msg,
                                html: true,
                            }); 
                        }else if($scope.formData.invoice_send_to == 'claimRep'){
                            msg = '<font color="red">You have selected invoices of multiple claim reps</font>';
                        }else{
                            msg = '<font color="red">You have selected invoices of multiple insurance companies</font>';
                        }
                    }

                    if(isValid){
                        swal({
                            title: "Are You Sure Want to Send?",
                            text: msg,
                            html: true,
                            type: "success",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {       
                                $rootScope.showLoader(true);   
                                invoiceFactory.sendInvoice('/api/invoice/sendInvoice',$scope.formData).success(function(res){
                                    $rootScope.showLoader(false);
                                    swal.close();

                                    if(res.error){
                                        Notification.error(res.result.message);
                                    }else{
                                        // $scope.sent_invoice_message_log = res.result.message;
                                        setTimeout(function () {
                                        swal({
                                            title: "Invoice(s) sent sucessfully!",
                                            text: "Do you want to clear the batch?", 
                                            type: "success",
                                            showCancelButton: true,
                                            confirmButtonColor: "#09375f",
                                            confirmButtonText: "Yes!",
                                            cancelButtonText: "No!",
                                            closeOnConfirm: true,
                                            closeOnCancel: true
                                        },
                                        function (isConfirm) {
                                            // Clear the Batch
                                            if (isConfirm) {
                                                $rootScope.batchInvoiceArray = [];
                                                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!
                                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');
                                            }
                                            $scope.closeModal();
                                            if($scope.formData.invoice_send_to == 'firm'){
                                                url=$state.href('accountReceivableNotes.browse',{attId:res.result.invoice.attorney_id});
                                                $window.open(url,'_blank');
                                            }

                                        });
                                        }, 500);
                                    }
                                }).error(function(err){
                                    $rootScope.showLoader(false);
                                    Notification.error(err);
                                });
                            }
                        });  
                    }
                }
            },
            size : 'auto',
            backdrop : false,
            keyboard : false,
            resolve : {
                ids : function (){return ids},
                via : function (){return via} 
            }
        });
        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    }
    $rootScope.sendAdvisedPaymentInfo = function(invoiceId,INV_NO){
        var modalInstance = $uibModal.open({
            templateUrl : 'modules/resources/templates/send_advised_payment_info_popup.html',
            controller : function($scope, $uibModalInstance, $http, apiUrl, invoiceId, Notification){

                $scope.formData = {
                    invoiceId : invoiceId,
                    subject :'Advised Payment Information Of Invoice # '+INV_NO
                };
                $scope.sent_invoice_message_log = {};

                $scope.closeModal =  function(){
                    $uibModalInstance.close();
                }
                $http.get(apiUrl + '/api/collector').success(function(res){
                    $scope.collectors = res.result;
                });

                $scope.changeCollector = function(email){
                    $scope.formData.toEmail =email;
                }
                
                
                $scope.send = function(isValid){
                    var msg = '';
                    if(isValid){
                        swal({
                            title: "Are You Sure Want to Send?",
                            text: msg,
                            html: true,
                            type: "success",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {       
                                $rootScope.showLoader(true);   
                                commonFactory.post('/api/send-advised-payment-info-mail',$scope.formData).success(function(res){
                                    $rootScope.showLoader(false);
                                    if(res.error){
                                        Notification.error(res.result.message);
                                    }
                                    else{
                                        Notification.success(res.result.message);
                                    }
                                }).error(function(err){
                                    $rootScope.showLoader(false);
                                    Notification.error(err);
                                });
                            }
                        });  
                    }
                }
            },
            size : 'auto',
            backdrop : false,
            keyboard : false,
            resolve : {
                invoiceId : function (){return invoiceId},
            }
        });
        modalInstance.opened.then(function(){
            $rootScope.showLoader(false);
        });
    }

    $rootScope.launchAticUser = function(){

        $rootScope.showLoader(true);
        var launchAticModal = $uibModal.open({
            templateUrl: "modules/resources/templates/launch_atic_user.html",
            controller: function($scope, $uibModalInstance, commonFactory, $filter){

                $scope.formSubmit = false;
                $scope.atic_user_id = ''; 
                $scope.aticUserList = {};

                commonFactory.get('/api/get-atic-user-list').success(function(response){
                    $scope.aticUserList = response.result;
                    $rootScope.showLoader(false);
                });

                $scope.launch = function(isValid){

                    $scope.formSubmit = true;
                    if(isValid){                        
                        swal({
                            title: "Are you sure want to launch with this user?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes, Launch it",
                            cancelButtonText: "No!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm){
                            if(isConfirm){
                                var aticUser = $filter('filter')($scope.aticUserList, $scope.atic_user_id);
                                var URL = $rootScope.depoHost +"/autologin.php?userid="+aticUser[0].username+"&password="+aticUser[0].password+"&script=audio_list_have_no_job_browse.php";
                                window.open(URL,'_blank');
                                $scope.closePopupWindow();
                            }
                        });
                    }
                }

                $scope.closePopupWindow = function(){
                    $uibModalInstance.close();
                };
            },

            keyboard: false,
            backdrop: false
        });
    }

    $rootScope.viewExhibits = function(JOB_NO){
        $rootScope.showLoader(true);
        $uibModal.open({
            templateUrl: "modules/resources/templates/view_exhibits.html",
            controller: ['$scope', '$uibModalInstance', 'Notification','apiUrl','$http', function ($scope, $uibModalInstance, Notification, apiUrl, $http) {

                $scope.JOB_NO = JOB_NO.toString();
                $scope.uibModalInstance = $uibModalInstance;
                $scope.url = $rootScope.depoHost+"/upload/exhibits/"+$scope.JOB_NO.substr(0,4);

                $http.get(apiUrl+"/api/get-exhibits-by-job/"+$scope.JOB_NO).success(function(res){
                    $rootScope.showLoader(false);
                    if(res.error){
                        Notification.error(res.result.message);
                    }else{
                        $scope.exhibits = res.result.data;

                        $scope.exhibit_comments = '';

                        if( res.result.exhibit_comments.length){
                            res.result.exhibit_comments.forEach(element => {
                                if(element.exhibit_comments){
                                    if($scope.exhibit_comments){
                                        $scope.exhibit_comments = $scope.exhibit_comments+' '+element.exhibit_comments
                                    }else{
                                        $scope.exhibit_comments = element.exhibit_comments
                                    }
                                }
                                
                            });
                        }
                    }
                });

                $scope.toggleSelectAll = function () {
                    angular.forEach($scope.exhibits, function (exhibit) {
                        exhibit.selected = $scope.selectAll;
                    });
                  };
            
                $scope.toggleSelectExhibit = function (exhibit) {
                    $scope.selectAll = $scope.exhibits.every(function (item) {
                        return item.selected;
                    });
                };

                $scope.hasSelectedItems = function () {
                    return $scope.exhibits.some(function (exhibit) {
                        return exhibit.selected;
                    });
                };
            
                $scope.downloadSelectedExhibits = function () {
                  $rootScope.showLoader(true);
                    var selectedExhibits = $scope.exhibits.filter(function (exhibit) {
                        return exhibit.selected;
                    });
  
                    if(selectedExhibits.length == 0){
                      Notification.error("Please select at least one file");
                    }else{
  
                      $scope.postData = {
                        jobno: $scope.JOB_NO,
                        url: $scope.url,
                        data: selectedExhibits
                      };
                      
                      $http.post(apiUrl + "/api/download-multiple-exhibits-by-job", $scope.postData)
                      .then(function (response) {
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.error || "Something went wrong!");
                        } else {
                            var fileUrl = apiUrl + "/" + $scope.JOB_NO + ".zip";
                            var fileName = $scope.JOB_NO + ".zip";
    
                            var downloadLink = document.createElement("a");
                            downloadLink.style.display = "none";
    
                            downloadLink.href = fileUrl;
                            downloadLink.download = fileName;

                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                            Notification.success("Zip file downloaded successfully");
                            $uibModalInstance.close();
                        }
                      })
                      .catch(function (error) {
                          Notification.error("Something went wrong!");
                      });
                    }
  
                };

            }],
            windowClass: 'full-width-model',
            keyboard: false,
            backdrop: false,
            size: 'lg'
        });
    }

    $rootScope.viewTranscripts = function(JOB_NO){
        
        var depoUrl = "matchingjobs.php?job_no="+JOB_NO;

        var enscript = encodeURIComponent(depoUrl);

        var auth_user = $rootScope.auth_user;

        var url = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
        
        $rootScope.launchIFrame(url);

    }

    $rootScope.viewDeopUrlPopup = function(depoUrl){
        
        var enscript = encodeURIComponent(depoUrl);

        var auth_user = $rootScope.auth_user;

        var url = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
        
        $rootScope.launchIFrame(url);

    }

    $rootScope.StatusIDForEmailAvailable = [1,2,3,4,5,6,7,8,27,28];
}

angular
    .module('deitz')
    .controller('MainCtrl', MainCtrl);

